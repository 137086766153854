import React, { useEffect, useState } from "react";
import Logo from "../../Assets/Images/profile.png";
import Edit from "@mui/icons-material/EditOutlined";
import { useMutation } from "@apollo/client";
import { UPDATE_USER } from "../../Graphql/User/Query";
import ToastAlert, { showToast } from "../Common/ToastAlert";
import Sidebar from "./SideBar";
import { Dialog } from "@mui/material";

const ProfilePage = () => {
  const [isEditingUsername, setIsEditingUsername] = useState(false);
  const [newUsername, setNewUsername] = useState(
    localStorage.getItem("username")
  );
  const [updateUser] = useMutation(UPDATE_USER);
  const [open, setOpen] = useState(true);
  const [contentWidth, setContentWidth] = useState(null);
  const [profilePicDialogOpen, setProfilePicDialogOpen] = useState(false);
  const [selectedProfilePic, setSelectedProfilePic] = useState(
    localStorage.getItem("profilepic") || Logo
  );
  const [tempSelectedPic, setTempSelectedPic] = useState(selectedProfilePic); // Temporarily selected pic

  const avatarImages = [
    "https://storage.googleapis.com/anpavatars/profile1.png",
    "https://storage.googleapis.com/anpavatars/profile2.png",
    "https://storage.googleapis.com/anpavatars/profile3.png",
    "https://storage.googleapis.com/anpavatars/profile4.png",
    "https://storage.googleapis.com/anpavatars/profile5.png",
    "https://storage.googleapis.com/anpavatars/profile6.png",
    "https://storage.googleapis.com/anpavatars/profile7.png",
    "https://storage.googleapis.com/anpavatars/profile8.png",
    "https://storage.googleapis.com/anpavatars/profile9.png",
    "https://storage.googleapis.com/anpavatars/profile10.png",
    "https://storage.googleapis.com/anpavatars/profile11.png",
    "https://storage.googleapis.com/anpavatars/profile12.png",
    "https://storage.googleapis.com/anpavatars/profile13.png",
    "https://storage.googleapis.com/anpavatars/profile14.png",
    "https://storage.googleapis.com/anpavatars/profile15.png",
    "https://storage.googleapis.com/anpavatars/profile16.png",
    "https://storage.googleapis.com/anpavatars/profile17.png",
    "https://storage.googleapis.com/anpavatars/profile18.png",
    "https://storage.googleapis.com/anpavatars/default.png",
  ];

  useEffect(() => {
    setContentWidth(open ? "78.5%" : "96.5%");
  }, [open]);

  const handleUsernameChange = (e) => setNewUsername(e.target.value);

  const handleUsernameUpdate = async () => {
    try {
      const userId = localStorage.getItem("userId");
      await updateUser({
        variables: {
          id: userId,
          profilepic : localStorage.getItem("profilepic"),
          username: newUsername || localStorage.getItem("username"),
        },
      });
      localStorage.setItem("username", newUsername);
      showToast("Username updated successfully!", "success");
      setIsEditingUsername(false);
    } catch (error) {
      showToast("Failed to update username.", "error");
      console.error("Error updating username:", error);
    }
  };

  const handleProfilePicSave = async () => {
    if (tempSelectedPic) {
      try {
        const userId = localStorage.getItem("userId");
        await updateUser({
          variables: {
            id: userId,
            username: localStorage.getItem("username"),
            profilepic: tempSelectedPic,
          },
        });
        localStorage.setItem("profilepic", tempSelectedPic);
        setSelectedProfilePic(tempSelectedPic);
        showToast("Profile picture updated successfully!", "success");
        setProfilePicDialogOpen(false);
      } catch (error) {
        showToast("Failed to update profile picture.", "error");
        console.error("Error updating profile picture:", error);
      }
    }
  };

  return (
    <div className="w-screen h-screen bg-img font-roboto flex flex-row">
      <div style={{ width: open ? "22%" : "60px" }}>
        <Sidebar open={open} setOpen={setOpen} />
      </div>
      <ToastAlert />
      <div style={{ width: contentWidth }} className="p-5">
      <div className="flex items-center bg-[#E9E0F6] rounded-full gap-6 w-full">
  <div className="relative w-[140px] h-[140px] overflow-hidden rounded-full">
    <img
      src={selectedProfilePic}
      alt="Profile"
      className="w-full h-full object-cover border-4 border-[#6750A4] rounded-full"
    />
    <div className="absolute bottom-0 left-0 w-full h-[20%] bg-white  flex items-center justify-center border-t-1 border-black  rounded-full">
      <button
        onClick={() => setProfilePicDialogOpen(true)}
        className="p-1 text-white"
      >
        <Edit className="w-5 h-5 text-black" />
      </button>
    </div>
  </div>


          {/* Rest of Profile Info */}
          <div className="flex justify-between items-center w-[80%] pl-5">
            <div className="flex items-center gap-2">
              <Edit
                style={{ width: 25, height: 25 }}
                className="ml-2 cursor-pointer"
                onClick={() => setIsEditingUsername(true)}
              />
              <span className="font-semibold text-[#6750A4] text-[18px]">
                Username:
              </span>
              {isEditingUsername ? (
                <div className="flex items-center">
                  <input
                    type="text"
                    value={newUsername}
                    onChange={handleUsernameChange}
                    className="p-2 border border-gray-300 rounded w-[200px] rounded-[36px] shadow"
                  />
                  <button
                    onClick={handleUsernameUpdate}
                    className="bg-bcolor text-white w-[100px] rounded-full p-2 mx-2"
                  >
                    Save
                  </button>
                </div>
              ) : (
                <div className="flex items-center">
                  {localStorage.getItem("username")}
                </div>
              )}
            </div>

            {/* Email Section */}
            <div className="flex items-center gap-2">
              <span className="font-semibold text-[#6750A4] text-[18px]">
                Email:
              </span>
              <span>{localStorage.getItem("email")}</span>
            </div>

            {/* Password Section */}
            {/* <div className="flex items-center gap-2">
              <Edit />
              <span className="font-semibold text-[#6750A4] text-[18px]">
                Password:
              </span>
              <span>•••••</span>
            </div> */}
          </div>
        </div>

        {/* Profile Picture Dialog */}
        <Dialog
          open={profilePicDialogOpen}
          PaperProps={{
            sx: {
              width: "auto",
              maxWidth : "1500px",
              height: "auto",
              maxHeight : "700px",
              borderRadius: "50px",
            },
          }}
          onClose={() => setProfilePicDialogOpen(false)}
        >
        <div className="bg-[#E8DEF8] flex flex-col justify-center items-center space-y-2 p-3 rounded-[50px] w-full">
            {/* Display selected image on top */}
          
       
                <img
                  src={tempSelectedPic || selectedProfilePic}
                  alt="Selected Profile"
                  className="w-[250px] h-[250px] object-cover rounded-full shadow mb-2"
                />
         
              <button
              onClick={handleProfilePicSave}
              disabled={!tempSelectedPic}
              className="h-10 bg-bcolor rounded-full px-6 py-2.5 text-white font-medium flex items-center justify-center bg-bcolor"
            >
              Save Changes
            </button>
      
            <div className="grid grid-cols-5 gap-x-[100px] gap-y-[40px] mb-4 w-full pt-3 px-5">
              {avatarImages.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt="Avatar"
                  onClick={() => setTempSelectedPic(image)}
                  className={`cursor-pointer w-[116px] h-[116px] rounded-full border-4  shadow ${
                    tempSelectedPic === image
                      ? "border-bcolor"
                      : ""
                  }`}
                />
              ))}
            </div>
       
            </div>
        </Dialog>
      </div>
    </div>
  );
};

export default ProfilePage;
