import React, { useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { nudgedata } from "../../Data/nudgelibrary";

const SearchableDropdown = ({
  onClose,
  addBehavior,
  stakeholder,
  addNudge,
  behaviorRefs
}) => {
  const [selectedNudge, setSelectedNudge] = useState(null);
  const [searchType, setSearchType] = useState("behavior");

  const handleadd = async (stakeholder, behavior, target, nudgeTypes) => {
    console.log("This was called", stakeholder, behavior, target);
  
    // Add behavior and retrieve its index
    const behaviorIndex = await addBehavior(stakeholder, behavior, target);
  
    // Add nudges after behavior is added
    for (const nudge of nudgeTypes.split(",")) {
      await addNudge(stakeholder, behaviorIndex, nudge);
    }
  
    // Close the modal
    onClose();
  
    // Scroll to the newly added behavior
    setTimeout(() => {
      const el = behaviorRefs.current[`${stakeholder}-${behaviorIndex}`];
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
        el.focus();
      }
    }, 100); // Small timeout to ensure DOM updates
  };

  const handleSelect = (event, value) => {
    setSelectedNudge(value);
  };

  const options = nudgedata
    .map((nudge) => nudge[searchType])
    .filter((value, index, self) => self.indexOf(value) === index);

  return (
    <div className="flex flex-col w-[100%] justify-center items-center p-5 space-y-5">
      <div className="w-[100%] flex flex-row justify-center items-center border-gray-400 ">
        <div className="flex flex-row justify-between w-[80%]">
          <label className="font-medium">Search By: </label>
          <div>
            <label className="flex items-center">
              <input
                type="checkbox"
                value="behavior"
                checked={searchType === "behavior"}
                onChange={(e) => setSearchType(e.target.value)}
                className="mr-2 w-5 h-5"
              />
              Problem
            </label>
          </div>
          <div>
            <label className="flex items-center">
              <input
                type="checkbox"
                value="target"
                checked={searchType === "target"}
                onChange={(e) => setSearchType(e.target.value)}
                className="mr-2 w-5 h-5"
              />
              Target
            </label>
          </div>
          <div>
            <label className="flex items-center">
              <input
                type="checkbox"
                value="nudgetype"
                checked={searchType === "nudgetype"}
                onChange={(e) => setSearchType(e.target.value)}
                className="mr-2 w-5 h-5"
              />
              Nudge Type
            </label>
          </div>
        </div>
      </div>

      <Autocomplete
        options={options}
        onChange={handleSelect}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              style: {
                borderRadius: "20px",
                backgroundColor: "white",
              },
            }}
          />
        )}
        style={{
          width: "100%",
          height: "40px",
          marginBottom :'10px'
        }}
      />

   
        
      {selectedNudge && (
        <div className="w-[100%] max-h-[350px] overflow-scroll rounded-lg">
          {nudgedata
            .filter((nudge) => nudge[searchType] === selectedNudge)
            .map((nudge, index) => (
              <div
                key={index}
                className="flex flex-col bg-img p-5 rounded-[15px] shadow-lg border-[1px] border-gray-400 mb-5 space-y-3"
              >
                <div className="w-full flex justify-end mr-3">

                <button
                  onClick={() =>
                    handleadd(
                      stakeholder.stakeholderIndex,
                      nudge.behavior,
                      nudge.target,
                      nudge.nudgetype
                    )
                  }
                  className="h-10 bg-bcolor rounded-full px-6 py-2.5 text-white font-medium flex items-center justify-center w-[200px]"
                >
              Add the Behavior
                </button>
                </div>
                <div className="flex flex-row">
                  <div className="font-medium bg-[#493971] rounded-l-[5px] text-white min-h-[40px] w-[40%] flex justify-center items-center text-center border border-[#4f378b]">Target behavior</div>
                  <div className="w-full min-h-[40px] bg-white rounded-r-[5px] border border-[#4f378b] p-2 flex items-center">
                    {nudge.behavior}
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="font-medium bg-[#493971] rounded-l-[5px] text-white min-h-[40px] w-[40%] flex justify-center items-center text-center border border-[#4f378b]">Ideal behavior</div>
                  <div className="w-full min-h-[40px] bg-white rounded-r-[5px] border border-[#4f378b] p-2 flex items-center">
                    {nudge.target}
                  </div>
                </div>
                <div className="flex flex-row ">
                  <div className="font-medium bg-[#493971] rounded-l-[5px] text-white min-h-[40px] w-[40%] flex justify-center items-center text-center border border-[#4f378b]">Nudge Type</div>
                  <div className="w-full min-h-[40px] bg-white rounded-r-[5px] border border-[#4f378b] p-2 flex items-center">
                    {nudge.nudgetype}
                  </div>
                </div>

                <div className="flex flex-row">
                  <div className="font-medium bg-[#493971] rounded-l-[5px] text-white min-h-[40px] w-[40%] flex justify-center items-center text-center border border-[#4f378b]">Biases</div>
                  <div className="w-full min-h-[40px] bg-white rounded-r-[5px] border border-[#4f378b] p-2 flex items-center">
                    {nudge.biases}
                  </div>
                </div>
           
             
              
                <div className="flex flex-row">
                  <div className="font-medium bg-[#493971] rounded-l-[5px] text-white min-h-[40px] w-[40%] flex justify-center items-center text-center border border-[#4f378b]">NNT Effect Size</div>
                  <div className="w-full min-h-[40px] bg-white rounded-r-[5px] border border-[#4f378b] p-2 flex items-center">
                    {nudge.nnt}
                  </div>
                </div>
                
                <div className="flex flex-row">
                  <div className="font-medium bg-[#493971] rounded-l-[5px] text-white min-h-[40px] w-[40%] flex justify-center items-center text-center border border-[#4f378b]">Cohen Effect Size</div>
                  <div className="w-full min-h-[40px] bg-white rounded-r-[5px] border border-[#4f378b] p-2 flex items-center">
                    {nudge.cohen}
                  </div>
                </div>
              
              
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;