import React, { useState } from "react";
import ReactPlayer from "react-player/youtube";
import { ReactComponent as LandingNext } from "../../Assets/Svgs/landingarroe.svg";

const LandingVideo
 = () => {
  const videos = [

    {
      id: 1,
      videoLink: "https://www.youtube.com/watch?v=1o0swnLHdpI",
      title: "Agile Science explainer",
      description: "Explains agile science",
      duration: "35 secs",
    },
    {
      id: 2,
      videoLink: "https://www.youtube.com/watch?v=IQMgjc5h8aYE",
      title: "Problem Statement",
      description: "Explains how to create a problem statement in the Agile Nudge Plus software",
      duration: "43 secs",
    },
    {
      id: 3,
      videoLink: "https://www.youtube.com/watch?v=dZ7QEwLlBAA",
      title: "Demand Index 2",
      description: "Explains how to finalize the demand index values in the software",
      duration: "115 secs",
    },
    {
      id: 4,
      videoLink: "https://youtu.be/imRpC1CfoYs",
      title: "Stakeholder Map",
      description: "Explains how to create a stakeholder and map the problem behavior, nudge type, etc.",
      duration: "38 secs",
    },
    {
      id: 5,
      videoLink: "https://youtu.be/fDXBTAD4fyM",
      title: "Nudge Design ",
      description: "Explains how to design a nudge and create sprints for your nudge",
      duration: "102 secs",
    },
  ];

  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);

  const handleNext = () => {
    if (currentVideoIndex < videos.length - 1) {
      // Move to the next video if it's not the last one
      setCurrentVideoIndex((prevIndex) => prevIndex + 1);
    } else {
      // If it's the last video, set videosViewed and reload the page
      localStorage.setItem("videosViewed", "true");
      window.location.reload();
    }
  };
  const handleSkipTutorial = () => {
    localStorage.setItem("videosViewed", "true");
    window.location.reload();
  };

  const { videoLink, title, description, duration } = videos[currentVideoIndex];

  return (
    <div className="w-screen h-screen flex flex-col font-roboto">
      <div className="relative flex flex-col w-full h-[100%] bg-cover bg-center rounded-[10px]">
        {/* Video player */}
        <ReactPlayer
          url={videoLink}
          playing={isPlaying} // Autoplay the video
          loop={false} // No looping
          controls={false} // Hide controls
          width="100%"
          height="100%"
          className="rounded-b-[15px] shadow-lg border-[1px] border-black"
          onEnded={handleNext} // Automatically go to the next video when it ends
        />

        {/* Video details and navigation */}
        <div className="absolute bottom-0 flex flex-row items-center text-2xl font-normal h-[74px] bg-white w-full  rounded-b-[10px]">
          <div className="flex flex-col items-center text-center w-[80%] pl-10">
            <h2 className="text-lg font-semibold">{title}</h2>
            <p className="text-sm text-gray-600">
              {description} - {duration}
            </p>
          </div>
          <div className="flex space-x-4">
          <button
            onClick={handleSkipTutorial}
            className="h-10 bg-bcolor rounded-full px-6 py-2.5 text-white font-medium flex items-center justify-center text-base"

          >
            Skip Tutorial
          </button>
          <button className="h-10 bg-bcolor rounded-full px-6 py-2.5 text-white font-medium flex items-center justify-center text-base" onClick={handleNext}>
            Next
          </button>
     
        </div>
        </div>
      </div>
    </div>
  );
};

export default LandingVideo
;