import React, { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { NavLink } from "react-router-dom";
import profile from "../../Assets/Images/pprofile.png";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_ALL_SUCCESSFUL_NUDGES,
  GET_COMMENTS_FOR_NUDGE,
  CREATE_COMMENT,
  UPDATE_COMMENT,
  DELETE_COMMENT,
} from "../../Graphql/User/Query";
import LoadingIndicator from "../Common/LoadingIndicator";
import ToastAlert, { showToast } from "../Common/ToastAlert";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { formatDistanceToNow, parseISO } from "date-fns";

const TutorialHowTo = () => {
  const [open, setOpen] = useState(true);
  const [contentWidth, setContentWidth] = useState("79.5%");
  const [selectedNudge, setSelectedNudge] = useState(null);

  // Current user from localStorage
  const currentUser = localStorage.getItem("username");

  // Local state for new comment (top-level or reply)
  const [newComment, setNewComment] = useState("");
  const [replyToCommentId, setReplyToCommentId] = useState(null);

  // Local state for editing a comment
  const [editCommentId, setEditCommentId] = useState(null);
  const [editCommentText, setEditCommentText] = useState("");

  // Track expanded/collapsed state of each comment's replies
  const [expandedComments, setExpandedComments] = useState({});

  // 1. Query: get all successful nudges
  const { data, loading, error } = useQuery(GET_ALL_SUCCESSFUL_NUDGES, {
    fetchPolicy: "network-only",
  });

  // 2. Lazy Query: load comments only when a user selects a nudge
  const [
    loadComments,
    { data: commentData, loading: commentLoading, error: commentError, refetch },
  ] = useLazyQuery(GET_COMMENTS_FOR_NUDGE, {
    fetchPolicy: "network-only",
  });

  // 3. Mutations: create, update, delete comments
  const [createComment] = useMutation(CREATE_COMMENT);
  const [updateComment] = useMutation(UPDATE_COMMENT);
  const [deleteComment] = useMutation(DELETE_COMMENT);

  useEffect(() => {
    setContentWidth(open ? "89.5%" : "96.5%");
  }, [open]);

  if (loading) return <LoadingIndicator />;
  if (error) {
    console.error("Error fetching successful nudges:", error);
    showToast("Failed to load successful nudges", "error");
    return null;
  }

  // Build a tree from the flat array of comments
  const buildCommentTree = (comments) => {
    // Create a map of commentId -> comment object
    const commentMap = {};
    comments.forEach((comment) => {
      commentMap[comment.id] = { ...comment, replies: [] };
    });

    // Link child comments to parents
    const commentTree = [];
    comments.forEach((comment) => {
      if (comment.parentCommentId) {
        const parent = commentMap[comment.parentCommentId];
        if (parent) {
          parent.replies.push(commentMap[comment.id]);
        }
      } else {
        // This is a top-level comment
        commentTree.push(commentMap[comment.id]);
      }
    });

    return commentTree;
  };

  // Helper: Toggle whether a comment’s replies are visible
  const toggleReplies = (commentId) => {
    setExpandedComments((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  // List of all successful nudges
  const successfulNudges = data?.getAllSuccessfulNudges || [];

  // Open modal & load comments
  const handleOpenModal = (nudge) => {
    setSelectedNudge(nudge);
    loadComments({ variables: { nudgeId: nudge.id } });
  };

  const handleCloseModal = () => {
    setSelectedNudge(null);
    setNewComment("");
    setReplyToCommentId(null);
    setEditCommentId(null);
    setEditCommentText("");
    setExpandedComments({});
  };

  // --- CREATE or REPLY ---
  const handleCreateComment = async () => {
    if (!newComment.trim() || !selectedNudge) return;

    try {
      await createComment({
        variables: {
          nudgeId: selectedNudge.id,
          data: {
            content: newComment,
            author: currentUser,
            parentCommentId: replyToCommentId, // null if new top-level
          },
        },
      });
      setNewComment("");
      setReplyToCommentId(null);
      if (refetch) refetch();
    } catch (err) {
      console.error("Error creating comment:", err);
      showToast("Failed to create comment", "error");
    }
  };

  // --- EDIT ---
  const handleEditComment = (commentId, originalText) => {
    setEditCommentId(commentId);
    setEditCommentText(originalText);
  };

  const handleUpdateComment = async (commentId) => {
    if (!editCommentText.trim() || !selectedNudge) return;
    try {
      await updateComment({
        variables: {
          nudgeId: selectedNudge.id,
          commentId,
          data: {
            content: editCommentText,
          },
        },
      });
      setEditCommentId(null);
      setEditCommentText("");
      if (refetch) refetch();
    } catch (err) {
      console.error("Error updating comment:", err);
      showToast("Failed to update comment", "error");
    }
  };

  // --- DELETE ---
  const handleDeleteComment = async (commentId) => {
    if (!window.confirm("Are you sure you want to delete this comment?")) return;
    try {
      await deleteComment({
        variables: {
          nudgeId: selectedNudge.id,
          commentId,
        },
      });
      if (refetch) refetch();
    } catch (err) {
      console.error("Error deleting comment:", err);
      showToast("Failed to delete comment", "error");
    }
  };

  // Format time difference for "2 hours ago", etc.
  const formatTimeDifference = (createdAt) => {
    return formatDistanceToNow(parseISO(createdAt), { addSuffix: true });
  };

  // Recursively renders a SINGLE comment (including its replies if expanded)
  const renderComment = (comment) => {
    const isAuthor = comment.author === currentUser;
    const isEditing = editCommentId === comment.id;
    const hasReplies = comment.replies && comment.replies.length > 0;
    const isExpanded = expandedComments[comment.id] || false;

    return (
      <div key={comment.id} className="ml-3 my-3 border-l pl-3">
        {/* Comment Header */}
        <div className="flex justify-between">
          <div className="text-sm font-medium">{comment.author || "Anonymous"}</div>
          {comment.createdAt && (
            <div className="text-xs text-gray-400">
              {formatTimeDifference(comment.createdAt)}
            </div>
          )}
        </div>

        {/* Comment Content or Edit Mode */}
        {!isEditing && <p className="text-sm">{comment.content}</p>}
        {isEditing && (
          <div className="mt-1">
            <textarea
              className="border rounded w-full p-1 text-sm"
              value={editCommentText}
              onChange={(e) => setEditCommentText(e.target.value)}
            />
            <div className="flex space-x-2 mt-1">
              <button
                onClick={() => handleUpdateComment(comment.id)}
                className="bg-blue-500 text-white px-2 py-1 rounded text-xs"
              >
                Save
              </button>
              <button
                onClick={() => {
                  setEditCommentId(null);
                  setEditCommentText("");
                }}
                className="bg-gray-300 text-black px-2 py-1 rounded text-xs"
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {/* Action Buttons (Reply, Edit, Delete) */}
        {!isEditing && (
          <div className="flex items-center space-x-3 mt-1 text-xs text-gray-600">
            <button onClick={() => setReplyToCommentId(comment.id)} className="hover:underline">
              Reply
            </button>
            {isAuthor && (
              <>
                <button
                  onClick={() => handleEditComment(comment.id, comment.content)}
                  className="hover:underline"
                >
                  Edit
                </button>
                <button onClick={() => handleDeleteComment(comment.id)} className="hover:underline">
                  Delete
                </button>
              </>
            )}
          </div>
        )}

        {/* Replies Toggle */}
        {hasReplies && (
          <div className="mt-1">
            {!isExpanded ? (
              <button
                onClick={() => toggleReplies(comment.id)}
                className="text-xs text-blue-500 underline"
              >
                View {comment.replies.length}{" "}
                {comment.replies.length === 1 ? "reply" : "replies"}
              </button>
            ) : (
              <>
                <button
                  onClick={() => toggleReplies(comment.id)}
                  className="text-xs text-blue-500 underline"
                >
                  Hide replies
                </button>
                <div className="ml-4">
                  {comment.replies.map((reply) => renderComment(reply))}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  };

  // Renders the top-level array of comments (each with nested replies)
  const renderCommentsList = (commentTree) => {
    if (!commentTree || commentTree.length === 0) {
      return <p className="text-sm text-gray-500">No comments yet. Be the first!</p>;
    }
    return (
      <div className="max-h-[350px] overflow-auto pr-2 border-t pt-2">
        {commentTree.map((comment) => renderComment(comment))}
      </div>
    );
  };

  return (
    <div className="flex h-screen font-roboto bg-img text-base">
      {/* Sidebar */}
      <div style={{ width: open ? "22%" : "60px" }}>
        <Sidebar open={open} setOpen={setOpen} />
      </div>

      {/* Main Content */}
      <div className="flex flex-col w-full" style={{ width: contentWidth }}>
        {/* Header */}
        <div className="flex items-center h-[78px] bg-primary text-white font-medium pl-10 flex-shrink-0">
          <NavLink to="/tutorial">
            <button className="bg-bcolor rounded-full px-6 py-2.5 flex items-center text-white">
              <ArrowBackIcon /> <span className="ml-2">Back</span>
            </button>
          </NavLink>
          <h1 className="text-2xl ml-5 opacity-50">Tutorials and Resources</h1>
          <h1 className="text-2xl ml-2"> &gt; Successful Nudges</h1>
        </div>

        {/* Nudge Cards */}
        <div className="w-full pl-5">
          <h1 className="text-[28px] mb-5 mt-10 pl-5 text-white">Recent Successful Nudges</h1>
          <div className="grid grid-cols-1 sm:grid-cols-4 gap-6 px-5 pb-5">
            {successfulNudges.map((nudge) => (
              <div
                key={nudge.id}
                className="pb-3 bg-[#fef7ff] rounded-[15px] relative overflow-hidden"
              >
                <div className="w-[100px] h-[20px] bg-[#eaddff] rounded-[10px] shadow justify-center items-center">
                  <div className="text-center text-[#49454f]/50 text-[11px] font-medium pt-1">
                    {formatTimeDifference(nudge.createdAt)}
                  </div>
                </div>
                <div className="w-full flex flex-col justify-center items-center space-y-2">
                  <img
                    className="w-[140px] h-[140px] rounded-full shadow-md"
                    src={profile}
                    alt="Profile"
                  />
                  <div className="text-[#1d1b20] text-base font-normal">
                    {nudge.sprintData?.sprinterName}
                  </div>
                  <div className="w-[90%] text-[#49454f] text-xs font-normal">
                    {nudge.summary.substring(0, 120) + "..."}
                  </div>
                  <div className="w-[95%] flex justify-end">
                    <button
                      className="h-10 px-6 py-2.5 justify-center items-center inline-flex text-[#65558f] shadow-md rounded-full"
                      onClick={() => handleOpenModal(nudge)}
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Modal with Nudge Details and Comments */}
        <Dialog
          open={!!selectedNudge}
          onClose={handleCloseModal}
          PaperProps={{
            style: { borderRadius: 20, maxWidth: "1000px", width: "100%" },
          }}
        >
          <DialogContent>
            {selectedNudge && (
              <div className="p-4">
                <div className="grid grid-cols-2 gap-6">
                  {/* Left Column: Nudge Details */}
                  <div className="border-r pr-3">
                    <h2 className="text-xl font-semibold text-center mb-4">Nudge Details</h2>
                    <div className="space-y-2 text-sm">
                      <p>
                        <strong>Sprint Name:</strong> {selectedNudge.sprintData?.name}
                      </p>
                      <p>
                        <strong>Sprinter Name:</strong> {selectedNudge.sprintData?.sprinterName}
                      </p>
                      <p>
                        <strong>Stakeholder Name:</strong> {selectedNudge.sprintData?.stakeholderName}
                      </p>
                      <p>
                        <strong>Nudge Type:</strong> {selectedNudge.sprintData?.nudgeName}
                      </p>
                      <p>
                        <strong>Behavior Name:</strong> {selectedNudge.sprintData?.behaviorName}
                      </p>
                      <p>
                        <strong>Problem Name:</strong> {selectedNudge.sprintData?.problemName}
                      </p>
                      <p>
                        <strong>Start Date:</strong>{" "}
                        {new Date(selectedNudge.sprintData?.startDate).toLocaleDateString()}
                      </p>
                      <p>
                        <strong>End Date:</strong>{" "}
                        {new Date(selectedNudge.sprintData?.endDate).toLocaleDateString()}
                      </p>
                      <p>
                        <strong>Status:</strong> {selectedNudge.sprintData?.status}
                      </p>
                      <p>
                        <strong>Summary:</strong> {selectedNudge.summary}
                      </p>
                      <h3 className="font-medium mt-4">Tasks:</h3>
                      <ul className="list-disc ml-5">
                        {selectedNudge.sprintData?.tasks?.map((task) => (
                          <li key={task.id}>
                            <strong>{task.name}</strong> - {task.status}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  {/* Right Column: Comments Section */}
                  <div className="flex flex-col">
                    <h2 className="text-xl font-semibold text-center mb-4">Comments</h2>

                    {commentLoading && <p>Loading comments...</p>}
                    {commentError && (
                      <p className="text-red-500">Error loading comments: {commentError.message}</p>
                    )}

                    {(() => {
                      // Build the comment tree and render it
                      const comments = commentData?.getCommentsForNudge || [];
                      const commentTree = buildCommentTree(comments);
                      return renderCommentsList(commentTree);
                    })()}

                    {/* New or Reply Comment Input */}
                    <div className="mt-4 border-t pt-2">
                      {replyToCommentId ? (
                        <p className="text-xs text-gray-600 mb-1">Replying to a comment...</p>
                      ) : (
                        <p className="text-xs text-gray-600 mb-1">Add a new top-level comment...</p>
                      )}
                      <textarea
                        className="w-full border p-2 rounded"
                        rows={3}
                        placeholder="Add a comment..."
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                      />
                      <div className="flex items-center mt-2 space-x-2">
                        <button
                          onClick={handleCreateComment}
                          className="bg-primary text-white rounded-full px-4 py-2"
                        >
                          Post
                        </button>
                        {replyToCommentId && (
                          <button
                            onClick={() => {
                              setReplyToCommentId(null);
                              setNewComment("");
                            }}
                            className="px-4 py-2 rounded-full bg-gray-300 text-black"
                          >
                            Cancel Reply
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Close button */}
                <div className="flex justify-end mt-4">
                  <button
                    onClick={handleCloseModal}
                    className="px-4 py-2 rounded-full bg-bcolor text-white font-medium"
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default TutorialHowTo;