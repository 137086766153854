import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_ALL_ORGANIZATIONS,
  CREATE_ORGANIZATION,
  DELETE_ORGANIZATION,
  UPDATE_ORGANIZATION,
  GET_ALL_USERS
} from "../../Graphql/User/Query";
import {
  Autocomplete,
  TextField,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { Delete, Visibility, RemoveCircleOutline } from "@mui/icons-material";
import ToastAlert, { showToast } from "../Common/ToastAlert";
import Sidebar from "./SideBar";
import { useNavigate } from "react-router-dom";

const ManageOrganizations = () => {
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(false);
  const [passwordInput, setPasswordInput] = useState("");

  useEffect(() => {
setAuthenticated(localStorage.getItem("authenticated") === "true"); 
  }, []);

  const checkPassword = () => {
    if (passwordInput === "agilenudgeplus") {
      setAuthenticated(true);
      localStorage.setItem("authenticated", "true");
    } else {
      navigate("/");
    }
  };

  const { data: orgData, loading: orgLoading } = useQuery(GET_ALL_ORGANIZATIONS, {
    skip: !authenticated
  });
  const { data: usersData, loading: usersLoading } = useQuery(GET_ALL_USERS, {
    skip: !authenticated
  });
  const [open, setOpen] = useState(false);
  const [contentWidth, setContentWidth] = useState("80%");
  const [createOrganization] = useMutation(CREATE_ORGANIZATION, {
    refetchQueries: [{ query: GET_ALL_ORGANIZATIONS }],
  });
  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION, {
    refetchQueries: [{ query: GET_ALL_ORGANIZATIONS }],
  });
  const [deleteOrganization] = useMutation(DELETE_ORGANIZATION, {
    refetchQueries: [{ query: GET_ALL_ORGANIZATIONS }],
  });
  
  useEffect(() => {
    setContentWidth(open ? "80%" : "96.5%");
  }, [open]);

  const [selectedOrg, setSelectedOrg] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [newOrgName, setNewOrgName] = useState("");
  const [members, setMembers] = useState([]);

  useEffect(() => {
    if (selectedOrg) {
      setMembers(selectedOrg.members || []);
    }
  }, [selectedOrg]);

  const handleCreateOrganization = async () => {
    try {
      await createOrganization({
        variables: { input: { name: newOrgName, members: [] } },
      });
      showToast("Organization created successfully!", "success");
      setCreateModalOpen(false);
      setNewOrgName("");
      setTimeout(() => {
        window.location.reload();
      }, 2000);

    } catch (error) {
      console.error("Error creating organization:", error);
      showToast("Failed to create organization. Please try again.", "error");
    }
  };

  const handleUpdateOrganization = async () => {
    try {
      await updateOrganization({
        variables: {
          orgId: selectedOrg.id,
          input: { name: selectedOrg.name, members },
        },
      });
      showToast("Organization updated successfully!", "success");
      setModalOpen(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error("Error updating organization:", error);
      showToast("Failed to update organization. Please try again.", "error");
    }
  };

  const handleDeleteOrganization = async (orgId) => {
    if (window.confirm("Are you sure you want to delete this organization?")) {
      try {
        await deleteOrganization({ variables: { orgId } });
        showToast("Organization deleted successfully!", "success");
      } catch (error) {
        console.error("Error deleting organization:", error);
        showToast("Failed to delete organization. Please try again.", "error");
      }
    }
  };

  const handleRemoveMember = (index) => {
    setMembers((prev) => prev.filter((_, i) => i !== index));
  };

  // If not authenticated, show password input
  if (!authenticated) {
    return (
      <div className="w-screen h-screen flex flex-col justify-center items-center bg-[#fdf8f8]">
        <h1 className="text-2xl font-semibold mb-4">Enter the password to continue:</h1>
        <input
          type="password"
          className="border border-gray-300 rounded p-2 mb-4"
          value={passwordInput}
          onChange={(e) => setPasswordInput(e.target.value)}
          placeholder="Password"
        />
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded-md"
          onClick={checkPassword}
        >
          Submit
        </button>
      </div>
    );
  }

  if (orgLoading || usersLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="w-screen h-screen font-roboto bg-[#fdf8f8] flex flex-row text-base">
      <div style={{ width: open ? "22%" : "60px" }}>
        <Sidebar open={open} setOpen={setOpen} />
      </div>

      <div
        className="flex flex-col items-center"
        style={{ width: contentWidth }}
      >
        <ToastAlert />
        <div className="flex w-[80%] justify-between items-center mb-6 mt-5">
          <h1 className="text-2xl font-semibold">Manage Organizations</h1>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded-md"
            onClick={() => setCreateModalOpen(true)}
          >
            Add New Organization
          </button>
        </div>

        <table className="table-auto w-[80%] border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-200">
              <th className="border border-gray-300 px-4 py-2 text-left">Organization Name</th>
              <th className="border border-gray-300 px-4 py-2 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {orgData.getAllOrganizations.map((org) => (
              <tr key={org.id}>
                <td className="border border-gray-300 px-4 py-2">{org.name}</td>
                <td className="border border-gray-300 px-4 py-2 text-center space-x-4">
                  <IconButton
                    onClick={() => {
                      setSelectedOrg(org);
                      setModalOpen(true);
                    }}
                    color="primary"
                  >
                    <Visibility />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDeleteOrganization(org.id)}
                    color="error"
                  >
                    <Delete />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Edit Organization Modal */}
        <Dialog open={isModalOpen} onClose={() => setModalOpen(false)} maxWidth="sm" fullWidth>
          <DialogTitle>Edit Organization</DialogTitle>
          <DialogContent>
            <TextField
              label="Organization Name"
              variant="outlined"
              fullWidth
              value={selectedOrg?.name || ""}
              onChange={(e) =>
                setSelectedOrg((prev) => ({ ...prev, name: e.target.value }))
              }
              className="mb-4"
            />
            <div className="space-y-4 mt-4">
              {members.map((member, index) => (
                <div key={index} className="flex items-center space-x-4">
                  <Autocomplete
                    options={usersData.allUsers}
                    style={{
                      width: 300, 
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                    }}
                    getOptionLabel={(option) => option.username || ""}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.id}>
                        {option.username} ({option.email})
                      </MenuItem>
                    )}
                    value={usersData.allUsers.find((user) => user.id === member.userId) || null}
                    onChange={(e, newValue) =>
                      setMembers((prev) =>
                        prev.map((m, i) =>
                          i === index ? { ...m, userId: newValue?.id || "" } : m
                        )
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select User"
                        fullWidth
                      />
                    )}
                  />
                  <select
                    className="w-48 border border-gray-300 rounded-lg p-2 focus:outline-none"
                    value={member.accessLevel}
                    style={{
                      width: 300, 
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                    }}
                    onChange={(e) =>
                      setMembers((prev) =>
                        prev.map((m, i) =>
                          i === index
                            ? { ...m, accessLevel: e.target.value }
                            : m
                        )
                      )
                    }
                  >
                    <option value="">Select Access Level</option>
                    <option value="Admin">Admin</option>
                    <option value="Creator">Creator</option>
                    <option value="Viewer">Viewer</option>
                  </select>
                  <IconButton
                    color="error"
                    onClick={() => handleRemoveMember(index)}
                  >
                    <RemoveCircleOutline />
                  </IconButton>
                </div>
              ))}
              <button
                type="button"
                className="px-4 py-2 bg-blue-500 text-white rounded-md"
                onClick={() => setMembers([...members, { userId: "", accessLevel: "" }])}
              >
                Add Member
              </button>
            </div>
            <div className="flex justify-end mt-4">
              <button
                className="px-4 py-2 bg-green-500 text-white rounded-md mr-2"
                onClick={handleUpdateOrganization}
              >
                Save
              </button>
              <button
                className="px-4 py-2 bg-gray-400 text-white rounded-md"
                onClick={() => setModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </DialogContent>
        </Dialog>

        {/* Add New Organization Modal */}
        <Dialog open={isCreateModalOpen} onClose={() => setCreateModalOpen(false)} maxWidth="sm" fullWidth>
          <DialogTitle>Add New Organization</DialogTitle>
          <DialogContent>
            <TextField
              label="Organization Name"
              variant="outlined"
              fullWidth
              value={newOrgName}
              onChange={(e) => setNewOrgName(e.target.value)}
              className="mb-4"
            />
            <div className="flex justify-end">
              <button
                className="px-4 py-2 bg-green-500 text-white rounded-md mr-2"
                onClick={handleCreateOrganization}
              >
                Create
              </button>
              <button
                className="px-4 py-2 bg-gray-400 text-white rounded-md"
                onClick={() => setCreateModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default ManageOrganizations;