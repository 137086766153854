import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { cloneDeep } from "lodash";

const TreeDiagram = ({
  data,
  sprints,
  isZoomEnabled,
  handleNodeClick,
  addChild,
  open,
}) => {
  const svgRef = useRef();
  const tooltipRef = useRef(null);
  const userId = localStorage.getItem("userId");
  const isNotAdmin = !data?.admins?.includes(userId);
  const getDaysLeft = (sprintData) => {
    const today = new Date();
    const startDate = new Date(sprintData.startDate);
    const endDate = new Date(sprintData.endDate);
    const daysLeft = Math.ceil((endDate - today) / (1000 * 60 * 60 * 24));
    return startDate > today
      ? "NS"
      : daysLeft >= 0
      ? `${daysLeft} days`
      : "Ended";
  };

  const drawTree = (el, data, sprints, zoomEnabled) => {
    d3.select(el).selectAll("*").remove();
    const tooltip = d3.select(tooltipRef.current);
    const fixedHeight = 900;
    const margin = { top: 0, right: 0, bottom: 0, left: 40 };
    const screenWidth = window.innerWidth;
    const minScale = (screenWidth / 1920) * 0.9;

    const calculateWidth = (node) => {
      let maxDepth = 0;
      const traverse = (n, depth) => {
        if (depth > maxDepth) maxDepth = depth;
        if (n.children)
          n.children.forEach((child) => traverse(child, depth + 1));
      };
      traverse(node, 0);
      return (maxDepth + 1) * 300;
    };

    const temp = calculateWidth(data);
    const dynamicWidth = temp > 800 ? temp : 800;

    const zoom = d3
      .zoom()
      .scaleExtent([0.5, 2.5])
      .on("zoom", function (event) {
        svg.attr("transform", event.transform);
      });

    const svg = d3
      .select(el)
      .append("svg")
      .attr("width", dynamicWidth + margin.left + margin.right)
      .attr("height", fixedHeight + margin.top + margin.bottom)
      .call(zoom.filter(() => zoomEnabled))
      .append("g")
      .attr("transform", `translate(${margin.left}, 0)`);

    const initialZoomTransform = d3.zoomIdentity
      .translate(margin.left, fixedHeight / 2)
      .scale(minScale);
    d3.select(el).select("svg").call(zoom.transform, initialZoomTransform);

    const clonedData = cloneDeep(data);

    // Add dummy sprint node if no sprints exist
    clonedData.children.forEach((stakeholder) => {
      stakeholder.children.forEach((behavior) => {
        behavior.children.forEach((nudge) => {
          nudge.children.forEach((sprinter) => {
            const relatedSprints = sprints.filter(
              (sprint) =>
                sprint.stakeholderId === stakeholder.id &&
                sprint.behaviorId === behavior.id &&
                sprint.nudgeId === nudge.id
            );
            sprinter.children = relatedSprints.map((sprint) => ({
              ...sprint,
              type: "SPRINT",
              children: [],
            }));
            if (sprinter.children.length === 0) {
              sprinter.children.push({
                id: `dummy-${sprinter.id}`,
                name: "Add Sprint",
                type: "DUMMY_SPRINT",
                children: [],
              });
            }
          });
        });
      });
    });

    const root = d3.hierarchy(clonedData);
    const treeLayout = d3
      .tree()
      .nodeSize([50, 170])
      .separation((a, b) => (a.depth === 1 && b.depth === 1 ? 5 : 3));
    treeLayout(root);

    const linkGenerator = (d) => {
      const sourceX = d.source.x;
      const sourceY = d.source.y;
      const targetX = d.target.x;
      const targetY = d.target.y;
      const shortenAmount = 40;
      return d.source.depth === 0
        ? `M${sourceY},${sourceX}V${targetX}H${targetY - shortenAmount}`
        : `M${sourceY},${sourceX}H${
            (sourceY + targetY) / 2
          }V${targetX}H${targetY}`;
    };

    svg
      .selectAll("path")
      .data(root.links())
      .enter()
      .append("path")
      .attr("d", linkGenerator)
      .attr("fill", "none")
      .attr("stroke", "#d8c2be")
      .attr("stroke-width", 1);

    const node = svg
      .selectAll("g.node")
      .data(root.descendants())
      .enter()
      .append("g")
      .attr("class", "node")
      .attr("transform", (d) => `translate(${d.y},${d.x})`)
      .on("click", (event, d) => {
        if (d.data.type === "DUMMY_SPRINT") {
          addChild(d);
        } else {
          handleNodeClick(d.data.id);
        }
      })
      .on("mouseover", (event, d) => {
        tooltip.style("opacity", 1).text(d.data.name);
      })
      .on("mousemove", (event) => {
        const { left, top } = svgRef.current.getBoundingClientRect();
        tooltip
          .style("left", `${event.pageX - left + 10}px`)
          .style("top", `${event.pageY - top - 20}px`);
      })
      .on("mouseout", () => {
        tooltip.style("opacity", 0);
      });
    const defs = svg.append("defs");

    const filter = defs
      .append("filter")
      .attr("id", "lighterShadow")
      .attr("x", "-50%")
      .attr("y", "-50%")
      .attr("width", "200%")
      .attr("height", "200%");

    filter
      .append("feOffset")
      .attr("result", "offOut")
      .attr("in", "SourceAlpha")
      .attr("dx", 2) // Increase the x offset for a stronger effect
      .attr("dy", 3); // Increase the y offset for a stronger effect

    filter
      .append("feGaussianBlur")
      .attr("result", "blurOut")
      .attr("in", "offOut")
      .attr("stdDeviation", 6); // Increase the blur radius for a softer, larger shadow

    filter
      .append("feColorMatrix")
      .attr("type", "matrix")
      .attr("values", "0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 0.4 0") // Control shadow opacity
      .attr("result", "colorOut");

    filter
      .append("feBlend")
      .attr("in", "SourceGraphic")
      .attr("in2", "colorOut")
      .attr("mode", "normal");
    // Regular nodes
    node
    .filter((d) => d.depth > 0 && d.data.type !== "DUMMY_SPRINT")
    .append("rect")
    .attr("width", 140)
    .attr("height", 40)
    .attr("x", -70)
    .attr("y", -20)
    .style("cursor", "pointer") 
    .attr("fill", (d) => {
      if (isNotAdmin) return '#CAC4D0'; // Set for non-admins
      if (!d.data.name || d.data.name.trim() === "") return '#CAC4D0'; // Set for empty names
      return d.depth === 5 ? getSprintColor(d.data) : 'white'; // Regular color logic
    })
    .attr("rx", 20)
    .attr("ry", 40)
    .attr("stroke", "none")
    .style("box-shadow", "0px 4px 4px rgba(0, 0, 0, 0.25)") // Shadow effect
    .attr("filter", "url(#lighterShadow)"); // Optional lighter shadow from defs

// Text labels for regular nodes, with placeholder text if `name` is empty
node
  .filter((d) => d.depth > 0 && d.data.type !== "DUMMY_SPRINT")
  .append("text")
  .style("font-size", "14px")
  .attr("dy", 5)
  .attr("x", 0)
  .style("cursor", "pointer") 
  .attr("text-anchor", "middle")
  .style("fill", (d) => {
    return !d.data.name || d.data.name.trim() === "" ? "white" : "black"; // White for empty names, black otherwise
  })
  .text((d) => {
    if (!d.data.name || d.data.name.trim() === "") {
      // Placeholder text based on depth
      switch (d.depth) {
        case 1:
          return "Add Stakeholder";
        case 2:
          return "Add Behavior";
        case 3:
          return "Add Nudge Type";
        case 4:
          return "Add Sprinter";
        case 5:
          return "Add Sprint";
        default:
          return "";
      }
    }
    // Display node name or truncate if too long
    return d.depth === 5
      ? d.data.name.length <= 7
        ? `${d.data.name} (${getDaysLeft(d.data)})`
        : `${d.data.name.substring(0, 7)}.. (${getDaysLeft(d.data)})`
      : d.data.name.length <= 10
      ? d.data.name
      : `${d.data.name.substring(0, 10)}...`;
  });
    // Dummy sprint node for adding a new sprint
    // Dummy sprint node for adding a new sprint
    node
      .filter((d) => d.data.type === "DUMMY_SPRINT")
      .append("rect")
      .attr("width", 130)
      .attr("height", 38)
      .attr("x", -70)
      .attr("y", -20)
      .attr("fill", "#cac4d0")
      .attr("rx", 20)
      .attr("ry", 40)
      .attr("stroke", "none")
      .style("cursor", "pointer")
      .style("box-shadow", "0px 4px 4px rgba(0, 0, 0, 0.25)") // Shadow effect
      .attr("filter", "url(#lighterShadow)") // Optional lighter shadow from defs
      .on("click", (event, d) => {
        event.stopPropagation();
        addChild(d.parent); // Pass the parent of the dummy node
      });

    // Add label text for dummy sprint node
    node
      .filter((d) => d.data.type === "DUMMY_SPRINT")
      .append("text")
      .attr("x", 0)
      .attr("dy", 5)
      .attr("text-anchor", "middle")
      .style("fill", "white")
      .style("font-size", "14px")
      .text("Add Sprint")
      .style("cursor", "pointer")
      .on("click", (event, d) => {
        event.stopPropagation();
        addChild(d.parent); // Pass the parent of the dummy node
      });

    // Add a circle and a plus sign inside it
    node
      .filter((d) => d.depth !== 3 && d.depth !== 5)
      .append("circle")
      .attr("cx", (d) => (d.depth === 0 ? 0 : 85))
      .attr("cy", (d) => (d.depth === 0 ? 0 : 0))
      .attr("r", 10)
      .attr("fill", "black")
      .style("cursor", "pointer")
      .on("click", (event, d) => {
        event.stopPropagation();
        addChild(d);
      });
    node
      .filter((d) => d.depth > 0)
      .append("text")
      .attr("y", -30) // Position above the rectangle
      .attr("x", 0)
      .attr("text-anchor", "middle")
      .style("font-size", "14px")
      .style("fill", "gray")
      .text((d) => {
        switch (d.depth) {
          case 1:
            return `Stakeholder ${d.parent.children.indexOf(d) + 1}`;
          case 2:
            return `Target Behavior ${d.parent.children.indexOf(d) + 1}`;
          case 3:
            return `Nudge Type ${d.parent.children.indexOf(d) + 1}`;
          case 4:
            return `Sprinter`;
          case 5:
            return `Sprint ${d.parent.children.indexOf(d) + 1}`;
          default:
            return "";
        }
      });
    // Add the plus sign inside the circle
    node
      .filter((d) => d.depth !== 3 && d.depth !== 5)
      .append("text")
      .attr("dy", 3)
      .attr("x", (d) => (d.depth === 0 ? 0 : 85))
      .attr("text-anchor", "middle")
      .style("fill", "white")
      .style("font-size", "14px")
      .style("cursor", "pointer")
      .text("+")
      .on("click", (event, d) => {
        event.stopPropagation();
        addChild(d);
      });
  };

  const getSprintColor = (sprintData) => {
    console.log(sprintData.status)
    if (sprintData.status === "Success") return "#28CA42";
    if (sprintData.status === "Terminated") return "#FF5F57";
    return "#FFBD2E";
  };

  useEffect(() => {
    if (data && sprints) {
      drawTree(svgRef.current, data, sprints, isZoomEnabled);
    }
  }, [data, sprints, isZoomEnabled, open]);

  return (
    <div className="w-[100%] h-screen bg-[#fdf8f8] relative overflow-x-auto overflow-y-hidden rounded-[36px] flex justify-start items-center">
     
      <div className="flex items-center" ref={svgRef}></div>
      <div ref={tooltipRef} className="custom-tooltip"></div>
    </div>
  );
};

export default TreeDiagram;
