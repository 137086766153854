import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { parseISO, format } from "date-fns";

const COLORS = ["#8BC34A", "#B3261E", "#FFBD2E"]; // Success, Termination, In Tolerance colors

const ProgressChart = ({ tasks, sprintData }) => {
  // Combine all reports into a unified data structure for the line chart
  const chartData = [];
  tasks.forEach((task) => {
    task.report?.forEach((report) => {
      const date = format(parseISO(report.reportedDate), "yyyy-MM-dd");
      const existingEntry = chartData.find((entry) => entry.date === date);

      if (existingEntry) {
        existingEntry[task.name] = report.reportedValue;
      } else {
        chartData.push({
          date,
          [task.name]: report.reportedValue,
        });
      }
    });
  });

  // Sort chartData by date
  chartData.sort((a, b) => new Date(a.date) - new Date(b.date));

  // Calculate task status summary for the pie chart
  const statusSummary = { Success: 0, Termination: 0, "In Tolerance": 0 };
  tasks.forEach((task) => {
    const status = task.status || "In Tolerance";
    statusSummary[status] += 1;
  });

  const pieData = Object.keys(statusSummary).map((key) => ({
    name: key,
    value: statusSummary[key],
  }));

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
      {/* Line Chart for Progress */}
      <div style={{ width: "100%", height: 300 }}>
        <ResponsiveContainer>
          <LineChart
            data={chartData}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            {/* Add a line for each task */}
            {tasks.map((task) => (
              <Line
                key={task.id}
                type="monotone"
                dataKey={task.name}
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>

      {/* Pie Chart for Task Status */}
      <div style={{ width: 400, height: 300 }}>
        <ResponsiveContainer>
          <PieChart>
            <Tooltip />
            <Legend />
            <Pie
              data={pieData}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={({ name, value }) => `${name}: ${value}`}
              outerRadius={100}
              fill="#8884d8"
              dataKey="value"
            >
              {pieData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ProgressChart;