import React, { useState, useEffect } from "react";
import { List, Box, Button } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_SPRINT, GET_SPRINT, CREATE_SUCCESSFUL_NUDGE } from "../../Graphql/User/Query";
import LoadingIndicator from "../Common/LoadingIndicator";
import ToastAlert, { showToast } from "../Common/ToastAlert";
import {
  differenceInHours,
  differenceInDays,
  differenceInWeeks,
  parseISO,
} from "date-fns";
import ProgressChart from "./ProgressChart"; // Adjust the path as needed
import AddCircleIcon from "@mui/icons-material/AddCircleOutline";
import ShareIcon from '@mui/icons-material/Share';
const SprintCalendar = ({ sprintData, open }) => {
  const [expandedTask, setExpandedTask] = useState(null); // Track expanded task
  const [reportedValues, setReportedValues] = useState({});
  const [localSprintData, setLocalSprintData] = useState(sprintData);

  const { data, refetch } = useQuery(GET_SPRINT, {
    variables: { sprintId: sprintData.id },
    onCompleted: (fetchedData) => {
      setLocalSprintData(fetchedData.getSprint); // Sync fetched data with local state
    },
    skip: !sprintData.id, // Skip query if no sprint is selected
  });

  const [createSuccessfulNudge, { loading: creatingNudge }] = useMutation(CREATE_SUCCESSFUL_NUDGE, {
    onCompleted: () => {
      showToast("Nudge shared successfully!", "success");
    },
    onError: (error) => {
      console.error("Error creating successful nudge:", error);
      showToast("Failed to share the nudge.", "error");
    },
  });

  const [updateSprint, { loading: updatingSprint }] = useMutation(
    UPDATE_SPRINT,
    {
      onCompleted: (updatedData) => {
        showToast("Sprint updated successfully", "success");
        setLocalSprintData(updatedData.updateSprint); // Sync updated data
      },
      onError: (error) => {
        console.error("Error updating sprint:", error);
        showToast("Error updating sprint", "error");
      },
    }
  );
  useEffect(() => {
    if (sprintData.id !== sprintData.id) {
      refetch({ sprintId: sprintData.id });
    }
  }, [sprintData.id, refetch, sprintData.id]);

  const toggleTaskDetails = (taskId) => {
    setExpandedTask((prev) => (prev === taskId ? null : taskId));
  };

  const handleReportValueChange = (taskId, value) => {
    setReportedValues((prev) => ({
      ...prev,
      [taskId]: value,
    }));
  };
  const renderCurrentValueField = (task) => {
    const dichotomousOptions = [
      { label: "", values: { termination: "", success: "" } },
      { label: "Yes / No", values: { termination: "No", success: "Yes" } },
      {
        label: "True / False",
        values: { termination: "False", success: "True" },
      },
      {
        label: "Male / Female",
        values: { termination: "Female", success: "Male" },
      },
      {
        label: "Greater / Lesser",
        values: { termination: "Lesser", success: "Greater" },
      },
      { label: "0 / 1", values: { termination: "0", success: "1" } },
    ];

    // Select appropriate option based on baseline
    const selectedOption =
      dichotomousOptions[task.baseline] || dichotomousOptions[0];
    return task.variableType === "Dichotomous" ? (
      <div className="flex items-center w-[50%]">
        <h1 className="font-medium bg-[#6750a4] h-[42px] rounded-l-[5px] w-[50%] flex justify-center items-center text-center text-white border border-[#4f378b]">
          Current Value
        </h1>
        <select
          value={
            reportedValues[task.id] === 1
              ? selectedOption.values.success
              : selectedOption.values.termination
          }
          onChange={(e) => {
            const isSuccess = e.target.value === selectedOption.values.success;
            handleReportValueChange(task.id, isSuccess ? 1 : 0);
          }}
          className="bg-white border-[1px] border-gray-400 rounded-r-[5px] text-black p-2 h-[42px] w-[50%]"
        >
          <option value={selectedOption.values.termination}>
            {selectedOption.values.termination}
          </option>
          <option value={selectedOption.values.success}>
            {selectedOption.values.success}
          </option>
        </select>
      </div>
    ) : (
      <div className="flex items-center w-[50%]">
        <h1 className="font-medium bg-[#6750a4] h-[42px] rounded-l-[5px] w-[50%] flex justify-center items-center text-center text-white border border-[#4f378b]">
          Current Value
        </h1>
        <input
          placeholder="Reported Value"
          type="number"
          value={reportedValues[task.id] || ""}
          onChange={(e) => handleReportValueChange(task.id, e.target.value)}
          className="bg-white border-[1px] border-gray-400 rounded-r-[5px] text-black p-2 h-[42px] w-[50%]"
        />
      </div>
    );
  };
  const handleSuccessfulNudges = async () => {
    try {
      // Create the successful nudge
      const { data } = await createSuccessfulNudge({
        variables: {
          data: {
            sprintData: localSprintData,
            summary: "", // Replace this with your dynamic summary generation if needed
          },
        },
      });

      if (data) {
        console.log("Successful nudge created:", data);
        const updatedSprintData = {
          ...localSprintData,
          sentToSuccess: true,
        };
        // Update the sprint to set sentToSuccess to true
        await updateSprint({
          variables: {
            sprintId: localSprintData.id,
            data: updatedSprintData
          },
        });

        console.log("Sprint updated with sentToSuccess set to true.");
        refetch();
      }
    } catch (error) {
      console.error("Error while sharing successful nudge or updating sprint:", error);
    }
  };
  const handleUpdateTaskReport = (task) => {
    const reportedValue = parseFloat(reportedValues[task.id] || "0");
    let reportStatus = "In Tolerance";
    const frequency = task.frequency?.toLowerCase(); // e.g., "hourly", "daily", "weekly"
    const now = new Date();


    // Check if an entry for the current frequency already exists
    const isDuplicateEntry = task.report?.some((report) => {
      const reportDate = parseISO(report.reportedDate);

      return (
        (frequency === "hour" && differenceInHours(now, reportDate) === 0) ||
        (frequency === "day" && differenceInDays(now, reportDate) === 0) ||
        (frequency === "week" && differenceInWeeks(now, reportDate) === 0)
      );
    });

    if (isDuplicateEntry) {
      showToast(
        `You have already reported a value for this ${frequency}.`,
        "error"
      );
      return;
    }

    // Validation for each variable type
    if (task.variableType === "Dichotomous") {
      if (reportedValue !== 0 && reportedValue !== 1) {
        showToast("Dichotomous value must be 0 or 1", "error");
        return;
      }
      reportStatus = reportedValue === 1 ? "Success" : "Termination";
    } else if (task.variableType === "Likert Scale") {
      const range = task.baseline === 1 ? 5 : task.baseline === 2 ? 7 : 10;
      if (reportedValue < 1 || reportedValue > range) {
        showToast(`Categorical value must be between 1 and ${range}`, "error");
        return;
      }
      const midValue = range / 2;
      reportStatus = reportedValue >= midValue ? "Success" : "Termination";
    } else if (task.variableType === "Time Interval") {
      if (reportedValue < 0) {
        showToast("Time Interval value must be 0 or greater", "error");
        return;
      }
      reportStatus = reportedValue >= task.goal ? "Success" : "Termination";
    } else if (task.variableType === "Continuous") {
      if (reportedValue > task.goal - task.baseline) {
        showToast(
          `Maximum allowable value is ${task.goal - task.baseline}`,
          "error"
        );
        return;
      }
      if (reportedValue < task.termination - task.baseline) {
        showToast(
          `Minimum allowable value is ${task.termination - task.baseline}`,
          "error"
        );
        return;
      }
      const startDate = localSprintData.startDate
        ? parseISO(localSprintData.startDate)
        : null;
      const daysElapsed = startDate ? differenceInDays(now, startDate) : 0;
      const cumulativeBaseline =
        task.baseline + task.successValue * daysElapsed;
      const cumulativeTermination =
        task.baseline - task.terminationValue * daysElapsed;
      reportStatus =
        reportedValue >= cumulativeBaseline
          ? "Success"
          : reportedValue <= cumulativeTermination
            ? "Termination"
            : "In Tolerance";
    }

    const newReportEntry = {
      reportedDate: now.toISOString(), // includes date and time down to seconds
      reportedValue,
      status: reportStatus,
    };
    let taskStatus;

    if (task.variableType === "Continuous") {
      // For continuous tasks, calculate cumulative total
      const currentCumulativeTotal =
        task.baseline + task.report?.reduce(
          (sum, report) => sum + report.reportedValue,
          0
        ) + reportedValue;

      if (currentCumulativeTotal >= task.goal) {
        taskStatus = "Success";
      } else if (currentCumulativeTotal <= task.termination) {
        taskStatus = "Termination";
      } else {
        taskStatus = "In Tolerance";
      }
    } else {
      // For non-continuous tasks, calculate success percentage including new entry
      const allReports = [...(task.report || []), newReportEntry]; // Include new report entry
      const totalSuccessEntries = allReports.filter(
        (report) => report.status === "Success"
      ).length;
      const percent = (totalSuccessEntries / allReports.length) * 100;

      taskStatus =
        percent >= task.goal
          ? "Success"
          : percent <= task.termination
            ? "Termination"
            : "In Tolerance";
    }
    const updatedTasks = localSprintData.tasks.map((t) =>
      t.id === task.id
        ? {
          ...t,
          status: taskStatus,
          report: [...(t.report || []), newReportEntry],
        }
        : t
    );

    const updatedSprintData = {
      ...localSprintData,
      tasks: updatedTasks,
    };

    updateSprint({
      variables: { sprintId: localSprintData.id, data: updatedSprintData },
      refetchQueries: [
        { query: GET_SPRINT, variables: { sprintId: localSprintData.id } },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        setLocalSprintData(updatedSprintData);
        setReportedValues((prev) => ({ ...prev, [task.id]: "" }));
        setExpandedTask(null);
        showToast("Value Reported Successfully", "success");
      },
      onError: (error) => console.error("Error updating sprint:", error),
    });
  };
  const renderReportedValue = (task, reportedValue) => {
    const dichotomousOptions = [
      { label: "", values: { termination: "", success: "" } },
      { label: "Yes / No", values: { termination: "No", success: "Yes" } },
      {
        label: "True / False",
        values: { termination: "False", success: "True" },
      },
      {
        label: "Male / Female",
        values: { termination: "Female", success: "Male" },
      },
      {
        label: "Greater / Lesser",
        values: { termination: "Lesser", success: "Greater" },
      },
      { label: "0 / 1", values: { termination: "0", success: "1" } },
    ];

    const selectedOption =
      dichotomousOptions[task.baseline] || dichotomousOptions[0];
    const displayValue =
      reportedValue === 1
        ? selectedOption.values.success
        : selectedOption.values.termination;

    return task.variableType === "Dichotomous" ? displayValue : reportedValue;
  };

  const renderStatusDropdown = (task) => {
    return (
      <div className="flex flex-row w-[100%]">
        <h1 className="font-medium bg-[#493971] h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-white border border-[#4f378b]">
          Status
        </h1>
        <select
          value={task.status || "In Tolerance"}
          onChange={(e) => handleStatusChange(task.id, e.target.value)}
          className="bg-white border-[1px] border-gray-400 rounded p-2 h-[42px] w-[50%]"
        >
          <option value="Success">Success</option>
          <option value="Termination">Termination</option>
          <option value="In Tolerance">In Tolerance</option>
        </select>
      </div>
    );
  };
  const renderInstructions = (task) => {
    let instructionText = "";
    let calculatedRange = 0;
    const dichotomousOptions = [
      "",
      "Yes / No",
      "True / False",
      "Male / Female",
      "Greater / Lesser",
      "0 / 1",
    ];
    const selectedOption = dichotomousOptions[task.baseline];

    switch (task.variableType) {
      case "Dichotomous":
        instructionText = `Report the value as "${selectedOption}", where the ${selectedOption.split("/")[0]
          } represents success and the ${selectedOption.split("/")[1]
          } represents termination.`;
        break;

      case "Likert Scale":
        const range = task.baseline === 1 ? 5 : task.baseline === 2 ? 7 : 10;
        calculatedRange = range / 2;
        instructionText = `Report a value between 1 and ${range}. Values greater than ${calculatedRange} indicate success, while values below ${calculatedRange} indicate failure.`;
        break;

      case "Continuous":
        const now = new Date();
        let unitsElapsed = 0;

        if (task.frequency === "Hour") {
          unitsElapsed = localSprintData.startDate
            ? differenceInHours(now, localSprintData.startDate)
            : 0;
        } else if (task.frequency === "Day") {
          unitsElapsed = localSprintData.startDate
            ? differenceInDays(now, localSprintData.startDate)
            : 0;
        } else if (task.frequency === "Week") {
          unitsElapsed = localSprintData.startDate
            ? differenceInWeeks(now, localSprintData.startDate)
            : 0;
        }

        // Calculate cumulative thresholds based on frequency-adjusted elapsed units
        const cumulativeBaseline =
          task.baseline + task.successValue * unitsElapsed;

        const cumulativeTermination =
          task.baseline - task.terminationValue * unitsElapsed;
        const currentCumulativeTotal =
          task.baseline +
          task.report?.reduce(
            (sum, report) => sum + report.reportedValue,
            0
          ) || task.baseline;

        const getStatusMessage = (
          value,
          task,
          cumulativeBaseline,
          cumulativeTermination
        ) => {
          if (value > task.goal) {
            return "You have achieved the goal. Please set the task to Success.";
          } else if (value < task.termination) {
            return "You are below the termination value. Terminate immediately.";
          } else if (value < cumulativeTermination) {
            return "You are below the threshold. Improve immediately.";
          } else if (value > cumulativeBaseline) {
            return "You are on track. Keep up the good work!";
          } else {
            return "You are in tolerance but can improve.";
          }
        };

        const statusMessage = getStatusMessage(
          currentCumulativeTotal,
          task,
          cumulativeBaseline,
          cumulativeTermination
        );

        instructionText = `
        <table style="width: 100%; border-collapse: collapse; color : black">
          <thead>
            <tr>
              <th style="border: 1px solid #ccc; padding: 8px;">Metric</th>
              <th style="border: 1px solid #ccc; padding: 8px;">Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="border: 1px solid #ccc; padding: 8px;">Current Cumulative Value</td>
              <td style="border: 1px solid #ccc; padding: 8px;">${currentCumulativeTotal.toFixed(2)}</td>
            </tr>
            <tr>
              <td style="border: 1px solid #ccc; padding: 8px;">Expected Value (as of today)</td>
              <td style="border: 1px solid #ccc; padding: 8px;">${cumulativeBaseline.toFixed(2)}</td>
            </tr>
            <tr>
              <td style="border: 1px solid #ccc; padding: 8px;">Termination Threshold (as of today)</td>
              <td style="border: 1px solid #ccc; padding: 8px;">${cumulativeTermination.toFixed(2)}</td>
            </tr>
            <tr>
              <td style="border: 1px solid #ccc; padding: 8px;">Current Status</td>
              <td style="border: 1px solid #ccc; padding: 8px;">${statusMessage}</td>
            </tr>
            <tr>
              <td style="border: 1px solid #ccc; padding: 8px;">Instructions</td>
              <td style="border: 1px solid #ccc; padding: 8px;">
                Report a positive or negative value for this ${task.frequency ? task.frequency.toLowerCase() : "period"} in the chart.
              </td>
            </tr>
            <tr>
              <td style="border: 1px solid #ccc; padding: 8px;">Example</td>
              <td style="border: 1px solid #ccc; padding: 8px;">
                Report only the change observed. If your cumulative value is 100 and increased by 10, report 10, not 110.
              </td>
            </tr>
          </tbody>
        </table>
      `;
        break;

      case "Time Interval":
        instructionText =
          "Report the value at this specific instance, representing the time interval being measured.";
        break;

      default:
        instructionText =
          "Please select a variable type for detailed instructions.";
    }

    // Render the instructionText with HTML line breaks
    return (
      <p
        className="text-gray-500 text-sm pb-3"
        dangerouslySetInnerHTML={{ __html: instructionText }}
      />
    );
  };
  const handleStatusChange = (taskId, newStatus) => {
    // Update the task's status in localSprintData
    const updatedTasks = localSprintData.tasks.map((task) =>
      task.id === taskId ? { ...task, status: newStatus } : task
    );
    const updatedSprintData = { ...localSprintData, tasks: updatedTasks };
    console.log(newStatus)
    setLocalSprintData(updatedSprintData);

    // Trigger the mutation to save the updated status
    updateSprint({
      variables: { sprintId: localSprintData.id, data: updatedSprintData },
      refetchQueries: [
        { query: GET_SPRINT, variables: { sprintId: localSprintData.id } },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        showToast("Task status updated successfully", "success");
      },
      onError: (error) => {
        console.error("Error updating task status:", error);
        showToast("Error updating task status", "error");
      },
    });
  };

  const calculateReportStatus = (reportedValue, task, index) => {
    let successThreshold, terminationThreshold, midValue;

    switch (task.variableType) {
      case "Dichotomous":
        // Dichotomous: 1 is Success, 0 is Termination
        return reportedValue === 1
          ? { className: "bg-[#8BC34A]", statusText: "Success" }
          : { className: "bg-[#B3261E]", statusText: "Termination" };

      case "Likert Scale":
        // Likert Scale: success if > midpoint, termination if <= midpoint
        const range = task.baseline === 1 ? 5 : task.baseline === 2 ? 7 : 10;
        midValue = range / 2;
        return reportedValue > midValue
          ? { className: "bg-[#8BC34A]", statusText: "Success" }
          : { className: "bg-[#B3261E]", statusText: "Termination" };

      case "Continuous":
        // Continuous: Success if cumulative baseline met, Termination if cumulative termination met
        const now = new Date();
        let daysElapsed = 0;

        if (task.frequency === "Hour") {
          daysElapsed = localSprintData.startDate
            ? differenceInHours(now, localSprintData.startDate)
            : 0;
        } else if (task.frequency === "Day") {
          daysElapsed = localSprintData.startDate
            ? differenceInDays(now, localSprintData.startDate)
            : 0;
        } else if (task.frequency === "Week") {
          daysElapsed = localSprintData.startDate
            ? differenceInWeeks(now, localSprintData.startDate)
            : 0;
        }
        // Calculate cumulative thresholds
        const cumulativeBaseline =
          task.baseline + task.successValue * daysElapsed;
        const cumulativeTermination =
          task.baseline - task.terminationValue * daysElapsed;

        // Calculate cumulative reported sum up to the current report's index
        const previousTotalReportedSum = (task.report || [])
          .slice(0, index) // Sum only up to the specified report index
          .reduce((sum, report) => sum + report.reportedValue, 0);

        const cumulativeReportedValue =
          previousTotalReportedSum + reportedValue + task.baseline;

        // Determine status based on cumulative comparison with thresholds
        if (cumulativeReportedValue >= cumulativeBaseline) {
          return { className: "bg-[#8BC34A]", statusText: "Success" };
        } else if (cumulativeReportedValue <= cumulativeTermination) {
          return { className: "bg-[#B3261E]", statusText: "Termination" };
        } else {
          return { className: "bg-[#FFBD2E]", statusText: "In Tolerance" };
        }

      case "Time Interval":
        // Time Interval: Success if >= goal, Termination if < goal
        successThreshold = task.goal;
        terminationThreshold = task.termination;
        return reportedValue >= successThreshold
          ? { className: "bg-[#8BC34A]", statusText: "Success" }
          : { className: "bg-[#B3261E]", statusText: "Termination" };

      default:
        // Default case if no type matches
        return { className: "bg-[#FFBD2E]", statusText: "In Tolerance" };
    }
  };
  const renderVariableFields = (task) => {
    const dichotomousOptions = [
      "",
      "Yes / No",
      "True / False",
      "Male / Female",
      "Greater / Lesser",
      "0 / 1",
    ];
    return (
      <div>
        {task.variableType === "Dichotomous" && (
          <div className="flex flex-row w-full pb-3">
            <h1 className="font-medium bg-[#493971] h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-white border border-[#4f378b]">
              Variable Value
            </h1>
            <div className="w-[60%] h-[42px] rounded-r-[5px] border border-[#4f378b] p-2 bg-[#CAC4D0] flex items-center ">
              {dichotomousOptions[task.baseline]}
            </div>
          </div>
        )}
        {task.variableType === "Continuous" && (
          <div className="space-y-1 pt-1">
            <div className="flex flex-row w-full justify-between py-2">
              <div className="flex flex-row w-[45%]">
                <h1 className="font-medium bg-[#493971] h-[42px] rounded-l-[5px] w-[60%] flex justify-center items-center text-center text-white border border-[#4f378b]">
                  Baseline
                </h1>
                <div className="w-[40%] h-[42px] rounded-r-[5px] border border-[#4f378b] p-2 bg-[#CAC4D0]">
                  {task.baseline}
                </div>
              </div>
              <div className="flex flex-row w-[45%] ">
                <h1 className="font-medium bg-[#493971] h-[42px] rounded-l-[5px] w-[60%] flex justify-center items-center text-center text-white border border-[#4f378b]">
                  Frequency
                </h1>
                <div className="w-[40%] h-[42px] rounded-r-[5px] border border-[#4f378b] p-2 bg-[#CAC4D0]">
                  {task.frequency}
                </div>
              </div>
            </div>
            <div className="flex flex-row w-full justify-between pb-2">
              <div className="flex flex-row w-[45%]">
                <h1 className="font-medium bg-[#493971] h-[42px] rounded-l-[5px] w-[60%] flex justify-center items-center text-center text-white border border-[#4f378b]">
                  Goal
                </h1>
                <div className="w-[40%] h-[42px] rounded-r-[5px] border border-[#4f378b] p-2 bg-[#CAC4D0]">
                  {task.goal}
                </div>
              </div>
              <div className="flex flex-row w-[45%] ">
                <h1 className="font-medium bg-[#493971] h-[42px] rounded-l-[5px] w-[60%] flex justify-center items-center text-center text-white border border-[#4f378b]">
                  Termination
                </h1>
                <div className="w-[40%] h-[42px] rounded-r-[5px] border border-[#4f378b] p-2 bg-[#CAC4D0]">
                  {task.termination}
                </div>
              </div>
            </div>
            <div className="flex flex-row w-full pb-3 justify-between">
              <div className="flex flex-row w-[45%]">
                <h1 className="font-medium bg-[#493971] h-[42px] rounded-l-[5px] w-[60%] flex justify-center items-center text-center text-white border border-[#4f378b]">
                  Success Value
                </h1>
                <div className="w-[40%] h-[42px] rounded-r-[5px] border border-[#4f378b] p-2 bg-[#CAC4D0]">
                  {task.successValue}
                </div>
              </div>
              <div className="flex flex-row w-[45%] ">
                <h1 className="font-medium bg-[#493971] h-[42px] rounded-l-[5px] w-[60%] flex justify-center items-center text-center text-white border border-[#4f378b]">
                  Termination Value
                </h1>
                <div className="w-[40%] h-[42px] rounded-r-[5px] border border-[#4f378b] p-2 bg-[#CAC4D0]">
                  {task.terminationValue}
                </div>
              </div>
            </div>
          </div>
        )}
        {task.variableType === "Time Interval" && (
          <div className="flex flex-row w-full pb-3">
            <div className="flex flex-row w-[45%]">
              <h1 className="font-medium bg-[#493971] h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-white border border-[#4f378b]">
                Goal
              </h1>
              <div className="w-[60%] h-[42px] rounded-r-[5px] border border-[#4f378b] p-2 bg-[#CAC4D0]">
                {task.goal}
              </div>
            </div>
          </div>
        )}
        {task.variableType === "Likert Scale" && (
          <div className="flex flex-row w-full pb-3">
            <div className="flex flex-row w-[45%]">
              <h1 className="font-medium bg-[#493971] h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-white border border-[#4f378b]">
                Scale
              </h1>
              <div className="w-[60%] h-[42px] rounded-r-[5px] border border-[#4f378b] p-2 bg-[#CAC4D0]">
                {task.baseline === 1 ? "1-5" : task.baseline === 2 ? "1-7" : "1-10"}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  if (updatingSprint) return <LoadingIndicator />;
  const handleSprintStatusChange = (newStatus) => {
    const updatedSprintData = { ...localSprintData, status: newStatus };

    // Update the local state for instant UI feedback
    setLocalSprintData(updatedSprintData);

    // Trigger the mutation to update sprint status in the backend
    updateSprint({
      variables: { sprintId: localSprintData.id, data: updatedSprintData },
      refetchQueries: [
        { query: GET_SPRINT, variables: { sprintId: localSprintData.id } },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        showToast("Sprint status updated successfully", "success");
      },
      onError: (error) => {
        console.error("Error updating sprint status:", error);
        showToast("Error updating sprint status", "error");
      },
    });
  };
  return (
    <div className="shadow-md shadow-gray-400 bg-[#fef7ff] flex flex-col rounded-[15px] w-full h-full font-roboto text-base overflow-scroll scrollbar border-[1px] border-gray-300">
      <ToastAlert />
      <div className="w-full flex justify-end p-2" onClick={handleSuccessfulNudges}> {localSprintData.status === 'Success' && !localSprintData.sentToSuccess  &&  <ShareIcon />}  </div>
      <div className="p-4 w-full space-y-4">
        {/* General Sprint Information */}
        <div className="font-medium flex flex-col w-full border-b-[1px] border-gray-300 pb-2">
          <div className="flex flex-row w-full ">
            <div className="flex flex-col space-y-1 w-[33%]">
              <h1 className="font-semibold">Stakeholder</h1>
              <h1 className="text-sm"> {localSprintData.stakeholderName}</h1>
            </div>
            <div className="flex flex-col space-y-1 w-[67%]">
              <h1 className="font-semibold">Target behavior</h1>
              <h1 className="text-sm"> {localSprintData.behaviorName}</h1>
            </div>
          </div>
        </div>
        <div className="font-medium flex flex-col w-full border-b-[1px] border-gray-300 pb-2">
          <div className="flex flex-row w-full ">
            <div className="flex flex-col space-y-1 w-[33%]">
              <h1 className="font-semibold">Nudge Type</h1>
              <h1 className="text-sm"> {localSprintData.nudgeName}</h1>
            </div>
            <div className="flex flex-col space-y-1 w-[33%]">
              <h1 className="font-semibold">Sprinter Name</h1>
              <h1 className="text-sm"> {localSprintData.sprinterName}</h1>
            </div>
            <div className="flex flex-col space-y-1 w-[33%]">
              <h1 className="font-semibold">Sprint Name</h1>
              <h1 className="text-sm"> {localSprintData.name}</h1>
            </div>
          </div>
        </div>
        <div className="flex flex-row w-[100%]">
          <h1 className="font-medium bg-[#493971] h-[42px] rounded-l-[5px] w-[40%] flex pl-2 items-center  text-white border border-[#4f378b]">
            Sprint Name
          </h1>
          <div className="w-[60%] h-[42px] rounded-r-[5px] border border-[#4f378b] p-2 bg-white">
            {localSprintData.name}
          </div>
        </div>
        <div className="flex flex-row w-full justify-between border-b-[1px] border-gray-400 pb-4">
          <div className="flex flex-row w-[45%] ">
            <h1 className="font-medium bg-[#493971] h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-white border border-[#4f378b]">
              Start Date
            </h1>
            <div className="w-[60%] h-[42px] rounded-r-[5px] border border-[#4f378b] p-2 bg-white flex justify-center items-center">
              {localSprintData.startDate}
            </div>
          </div>
          <div className="flex flex-row w-[45%]">
            <h1 className="font-medium bg-[#493971] h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-white border border-[#4f378b]">
              End Date
            </h1>
            <div className="w-[60%] h-[42px] rounded-r-[5px] border border-[#4f378b] p-2 bg-white flex justify-center items-center">
              {localSprintData.endDate}
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-1 w-[70%]">
          <h1 className="font-semibold">Sprint Status</h1>
          <div className="flex items-center space-x-4">
            <label htmlFor="sprint-status" className="font-medium text-gray-700">
              Sprint Status:
            </label>
            <select
              id="sprint-status"
              value={localSprintData.status}
              onChange={(e) => handleSprintStatusChange(e.target.value)}
              className="border border-gray-300 rounded-md p-2 text-gray-700 focus:outline-none focus:ring focus:ring-indigo-500"
            >
              <option value="Success">Success</option>
              <option value="Termination">Termination</option>
              <option value="In Tolerance">In Tolerance</option>
            </select>
          </div>
        </div>
        <List>
          <h1 className="font-semibold ml-4">Behavioral Nudges:</h1>
          {localSprintData.tasks.map((task) => (
            <div key={task.id} className="border-b-[1px] border-gray-300">
              <div className="flex justify-between items-center px-4 py-2">
                <div className="w-[75%] min-h-[57px] rounded-[5px] border border-[#4f378b] p-2 bg-[#CAC4D0]">
                  {task.name}
                </div>
                <button
                  onClick={() => toggleTaskDetails(task.id)}
                  className="bg-bcolor rounded-full px-6 py-2.5 text-white font-medium flex items-center justify-center text-base"
                >
                  {expandedTask === task.id ? (
                    "Done"
                  ) : (
                    <div className="flex flex-row justify-center items-center space-x-2">
                      <AddCircleIcon /> <h1>Report</h1>
                    </div>
                  )}
                </button>
              </div>

              {expandedTask === task.id && (
                <Box className="px-4 py-2 space-y-3">
                  <div className="flex flex-row w-[100%]">
                    <h1 className="font-medium bg-[#493971] h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-white border border-[#4f378b]">
                      Variable Type
                    </h1>
                    <div className="w-[60%] h-[42px] rounded-r-[5px] border border-[#4f378b] p-2 bg-[#CAC4D0]">
                      {task.variableType === "Likert Scale"
                        ? "Categorical"
                        : task.variableType}
                    </div>
                  </div>
                  {renderVariableFields(task)}
                  {renderStatusDropdown(task)}
                  <h1 className="text-gray-400 text-[18px]">
                    Question: {task.variableDescription} ?
                  </h1>
                  {renderInstructions(task)}
                  <ProgressChart tasks={localSprintData.tasks} sprintData={localSprintData} />
                  {new Date(localSprintData.endDate) < Date.now() ? (
                    <h1 className="font-semibold">Your Sprint Has Ended</h1>
                  ) : task.variableType === "Continuous" &&
                    task.status === "Success" ? (
                    <h1 className="font-semibold">
                      You have reached the success no need to report anymore!
                      Congrats!!!
                    </h1>
                  ) : (
                    <div className="flex items-center w-full">
                      {renderCurrentValueField(task)}
                      <button
                        className="bg-bcolor rounded-full px-6 py-2.5 text-white font-medium ml-3"
                        onClick={() => handleUpdateTaskReport(task)}
                      >
                        Report Value
                      </button>
                    </div>
                  )}

                  {task.report && task.report.length > 0 && (
                    <div className="mt-3">
                      {task.report.map((report, index) => {
                        const { className, statusText } = calculateReportStatus(
                          report.reportedValue,
                          task,
                          index
                        );
                        return (
                          <div
                            key={index}
                            className="flex flex-row mb-2 justify-between w-full"
                          >
                            <div className="flex items-center w-[30%]">
                              <div className="font-medium bg-[#493971] h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-white border border-[#4f378b]">
                                Date
                              </div>
                              <div className="w-[60%] h-[42px] rounded-r-[5px] border border-[#4f378b] p-2 bg-[#CAC4D0]">
                                {open
                                  ? report.reportedDate.substring(2, 10)
                                  : report.reportedDate.substring(0, 10)}
                              </div>
                            </div>
                            <div className="flex items-center w-[30%]">
                              <div className="font-medium bg-[#493971] h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-white border border-[#4f378b]">
                                Value
                              </div>
                              <div className="w-[60%] h-[42px] rounded-r-[5px] border border-[#4f378b] p-2 bg-[#CAC4D0]">
                                {renderReportedValue(
                                  task,
                                  report.reportedValue
                                )}
                              </div>
                            </div>
                            <div className="flex items-center w-[30%]">
                              <div className="font-medium bg-[#493971] h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-white border border-[#4f378b]">
                                Status
                              </div>
                              <div
                                className={`flex justify-center items-center p-2 rounded-r-[5px] w-[60%] ${className} border-[1px] border-gray-400 h-[42px]`}
                              >
                                {statusText}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </Box>
              )}
            </div>
          ))}
        </List>
      </div>
    </div>
  );
};

export default SprintCalendar;
