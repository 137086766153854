import React, { useState, useEffect } from "react";
import {
  Drawer,
  IconButton,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Collapse as MuiCollapse,
  Switch,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Autocomplete
} from "@mui/material";
import { ReactComponent as Radio } from "../../Assets/Svgs/radio.svg";
import BusinessIcon from "@mui/icons-material/Business";
import EditOutlined from "@mui/icons-material/EditOutlined";
import HelpIcon from "@mui/icons-material/Help";

import {
  ExpandLess,
  ExpandMore,
  AddCircleOutline as AddCircleIcon,
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
  Error as ErrorIcon,
  Logout,
  RemoveCircleOutline,
  Delete
} from "@mui/icons-material";
import StyledTooltip from "./StyledTooltip";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_ALL_USER_PROBLEMS,
  GET_USER_ORGANIZATIONS,
  GET_ALL_SPRINTS,
  GET_ALL_USERS,
  UPDATE_ORGANIZATION
} from "../../Graphql/User/Query";
import logo from "../../Assets/Images/anplogo.png";
import ToastAlert, { showToast } from "../Common/ToastAlert";
import { NavLink } from "react-router-dom";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 58,
  height: 32,
  padding: 0,
  display: "flex",
  "&:hover": { backgroundColor: "transparent" },
  "& .MuiSwitch-switchBase": {
    padding: 3,
    "&.Mui-checked": {
      transform: "translateX(28px)",
      color: "#65558F",
      "& + .MuiSwitch-track": { backgroundColor: "#65558F" },
    },
    "&:hover": { backgroundColor: "transparent" },
  },
  "& .MuiSwitch-thumb": {
    padding: 13,
    backgroundColor: "#fff",
  },
  "& .MuiSwitch-track": {
    borderRadius: 15,
    backgroundColor: "#65558F",
    opacity: 1,
  },
}));

const Sidebar = ({ open, setOpen }) => {
  const [openMenus, setOpenMenus] = useState({});
  const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(
    localStorage.getItem("selectedMenu")
  );
  const [view, setView] = useState("Admin");
  const [openOrgModal, setOpenOrgModal] = useState(false);
  const [currentOrg, setCurrentOrg] = useState(null);
  const [members, setMembers] = useState([]);
  const [userSprintsGrouped, setUserSprintsGrouped] = useState({});
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  


  const { data: orgData, loading: orgLoading } = useQuery(
    GET_USER_ORGANIZATIONS,
    {
      variables: { userId },
    }
  );
  const { data: problemData, loading: problemLoading } = useQuery(
    GET_ALL_USER_PROBLEMS,
    { variables: { userId } }
  );
  const { data: sprintData, loading: sprintLoading } = useQuery(GET_ALL_SPRINTS);
  const { data: usersData, loading: usersLoading } = useQuery(GET_ALL_USERS);
  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION, {
    refetchQueries: [{ query: GET_USER_ORGANIZATIONS, variables: { userId } }],
  });

  useEffect(() => {
    setSelectedMenu(null);
    localStorage.removeItem("selectedMenu");
    localStorage.removeItem("openMenus");
  }, []);

  useEffect(() => {
    const savedOpenMenus = JSON.parse(localStorage.getItem("openMenus")) || {};
    setOpenMenus(savedOpenMenus);
  }, []);

  useEffect(() => {
    if (currentOrg) {
      setMembers(currentOrg.members || []);
    }
  }, [currentOrg]);
  useEffect(() => {
    if (sprintData && sprintData.getAllSprints && orgData && orgData.getUserOrganizations) {
      const groupedSprints = {};
  
      sprintData.getAllSprints.forEach((sprint) => {
        if (sprint.sprinterId === userId) {
          // Find the organization associated with the sprint
          const organization = orgData.getUserOrganizations.find((org) =>
            org.id === sprint.orgId
          );
  
          const orgName = organization?.name || "Unknown Organization";
          const problemName = sprint.problemName || "Unknown Problem";
  
          // Initialize organization grouping
          if (!groupedSprints[orgName]) {
            groupedSprints[orgName] = {};
          }
  
          // Initialize problem grouping within the organization
          if (!groupedSprints[orgName][problemName]) {
            groupedSprints[orgName][problemName] = {
              problemId: sprint.problemId,
              sprints: [],
            };
          }
  
          // Add the sprint under the problem within the organization
          groupedSprints[orgName][problemName].sprints.push(sprint);
        }
      });
  
      setUserSprintsGrouped(groupedSprints);
    }
  }, [sprintData, orgData, userId]);

  const handleViewToggle = () => setView(view === "Admin" ? "Sprint" : "Admin");

  const toggleSidebar = () => {
    const newSidebarState = !open;
    setOpen(newSidebarState);
    localStorage.setItem("sidebarOpen", newSidebarState);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  const handleProfileClick = (event) => setProfileMenuAnchor(event.currentTarget);
  const handleCloseProfileMenu = () => setProfileMenuAnchor(null);
  const handleEditProfile = () => {
    navigate("/profile");
    handleCloseProfileMenu();
  };
  const handleCreateOrg = () => {
    navigate("/organizations");
    handleCloseProfileMenu();
  };

  const toggleMenu = (menuId, problemId, sprint = null) => {
    const updatedMenus = {
      ...openMenus,
      [menuId]: !openMenus[menuId],
    };
    setOpenMenus(updatedMenus);
    localStorage.setItem("openMenus", JSON.stringify(updatedMenus));

    if (sprint) {
      navigate("/nudgebuilder/custombuilder", {
        state: { sprintfromsidebar: sprint, problemId },
      });
    } else {
      navigate("/nudgebuilder/custombuilder", {
        state: { sprintfromsidebar: null, problemId },
      });
    }
  };

  const calculateSprintProgress = (sprint) => {
    const today = new Date();
    const startDate = new Date(sprint.startDate);
    const endDate = new Date(sprint.endDate);

    if (today < startDate) return 0;
    if (today > endDate) return 100;

    const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
    const elapsedDays = (today - startDate) / (1000 * 60 * 60 * 24);

    return Math.min((elapsedDays / totalDays) * 100, 100);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "In Progress":
        return "#FFBD2E";
      case "Success":
        return "#8BC34A";
      case "Terminated":
        return "#B3261E";
      default:
        return "#FFBD2E";
    }
  };

  const userOrganizations = orgData?.getUserOrganizations || [];
  const userProblems = problemData?.getAllUserProblems || [];

  // group problems by orgId
  const groupedProblems = userOrganizations.reduce((acc, org) => {
    const matchedProblems = userProblems.filter((p) => p.orgId === org.id);
    acc[org.id] = { orgName: org.name, problems: matchedProblems };
    return acc;
  }, {});

  const getUniqueSprinters = (problem) => {
    const sprinters = [];
    problem.children.forEach((stakeholder) => {
      stakeholder.children.forEach((behavior) => {
        behavior.children.forEach((nudge) => {
          nudge.children.forEach((sprinter) => {
            if (!sprinters.find((s) => s.sprinterId === sprinter.sprinterId)) {
              sprinters.push(sprinter);
            }
          });
        });
      });
    });
    return sprinters;
  };

  const getSprintsBySprinter = (problemId, sprinterName) => {
    return sprintData?.getAllSprints.filter(
      (sprint) =>
        sprint.problemId === problemId && sprint.sprinterName === sprinterName
    );
  };

  const calculateCompletion = (problem) => {
    if (!sprintData) return 0;
    let totalNudgeTypes = 0;
    let nudgeTypesWithSprints = 0;
    let totalProgress = 0;

    problem.children.forEach((stakeholder) => {
      stakeholder.children.forEach((behavior) => {
        behavior.children.forEach((nudge) => {
          totalNudgeTypes += 1;
          const hasSprint = sprintData?.getAllSprints.some(
            (s) => s.problemId === problem.id && s.nudgeId === nudge.id
          );
          if (!hasSprint) {
            totalProgress += 0;
          } else {
            nudgeTypesWithSprints += 1;
            const sprintProgress = sprintData.getAllSprints
              .filter((s) => s.problemId === problem.id && s.nudgeId === nudge.id)
              .reduce((sum, s) => sum + calculateSprintProgress(s), 0);
            totalProgress += sprintProgress / nudgeTypesWithSprints;
          }
        });
      });
    });

    const completionPercentage =
      totalNudgeTypes === 0 ? 0 : totalProgress / totalNudgeTypes;
    return completionPercentage !== 0 ? completionPercentage : 10;
  };



  const handleOpenOrgModal = (org) => {
    setCurrentOrg(org);
    setMembers(org.members || []);
    setOpenOrgModal(true);
  };

  const handleCloseOrgModal = () => {
    setOpenOrgModal(false);
  };

  const handleRemoveMember = (index) => {
    setMembers((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSaveOrg = async () => {
    try {
      await updateOrganization({
        variables: {
          orgId: currentOrg.id,
          input: {
            name: currentOrg.name,
            members: members,
          },
        },
      });
      showToast("Organization updated successfully!", "success");
      handleCloseOrgModal();
    } catch (error) {
      console.error("Error updating organization:", error);
      showToast("Failed to update organization. Please try again.", "error");
    }
  };

  if (orgLoading || problemLoading || sprintLoading || usersLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div
      className={`font-roboto text-base ${profileMenuAnchor ? "blur-background" : ""
        }`}
    >
      <Drawer
        variant="permanent"
        sx={{
          width: open ? "20%" : "90px",
          "& .MuiDrawer-paper": {
            width: open ? "20%" : "90px",
            overflowX: "hidden",
          },
        }}
      >
        <div className="flex flex-row justify-between items-center bg-primary p-4">
          <img src={logo} className="w-[40px] h-[36px]" alt="Logo" />
          <StyledTooltip title={`Expand-Collapse Sidebar`}>
            <IconButton onClick={toggleSidebar}>
              {open ? <ChevronLeftIcon className="text-white" /> : <ChevronRightIcon className="text-white" />}
            </IconButton>
          </StyledTooltip>
        </div>

        {open ? (
          <div className="w-full flex justify-center items-center -mt-7">
            <StyledTooltip title={`Profile Settings`}>
              <img
                src={localStorage.getItem("profilepic")}
                className={`w-[58px] h-[58px] rounded-full shadow p-[1px] bg-white cursor-pointer ${profileMenuAnchor ? "border-4 border-[#6750A4]" : "border-2 border-white"
                  }`}
                alt="Profile"
                onClick={handleProfileClick}
              />
            </StyledTooltip>
          </div>
        ) : (
          <div className="w-full flex justify-center items-center mt-1">
            <StyledTooltip title={`Profile Settings`}>
              <img
                src={localStorage.getItem("profilepic")}
                className={`w-[50px] h-[50px] rounded-full shadow p-[1px] bg-white cursor-pointer ${profileMenuAnchor ? "border-4 border-[#6750A4]" : "border-2 border-white"
                  }`}
                alt="Profile"
                onClick={handleProfileClick}
              />
            </StyledTooltip>
          </div>
        )}

        <Menu
          anchorEl={profileMenuAnchor}
          open={Boolean(profileMenuAnchor)}
          onClose={handleCloseProfileMenu}
          PaperProps={{ style: { backgroundColor: "#F3EDF7" } }}
        >
          <MenuItem onClick={handleEditProfile} style={{ fontSize: "16px" }}>
            <EditOutlined className="mr-2" /> Edit Profile
          </MenuItem>
          <MenuItem onClick={handleCreateOrg} style={{ fontSize: "16px" }}>
            <BusinessIcon className="mr-2" /> Organization
          </MenuItem>
          <MenuItem onClick={handleLogout} style={{ fontSize: "16px" }}>
            <Logout className="mr-2" /> Logout
          </MenuItem>
        </Menu>
        <div className="px-3 w-full flex flex-col space-y-5">
          {open ? (
            <NavLink to="/">
              <button className="w-full h-14 bg-[#e8def8] rounded-full flex items-center justify-center px-4 text-[#4a4459] font-semibold mt-5">
                <AddCircleIcon className="mr-2" />
                <StyledTooltip title={`Generate Problem Statement`}>
                  Generate Problem Statement
                </StyledTooltip>
              </button>
            </NavLink>
          ) : (
            <NavLink to="/">
              <div className="bg-[#e8def8] p-1 rounded-full mt-2 w-[65px] h-[56px] flex justify-center items-center">
                <StyledTooltip title={`Generate Problem Statement`}>
                  <AddCircleIcon />
                </StyledTooltip>
              </div>
            </NavLink>
          )}
          {open && (
            <div className="text-center mt-2 mb-2">
              {(() => {
                const selectedOrgId = localStorage.getItem("orgId");
                const selectedOrgName =
                  selectedOrgId && groupedProblems[selectedOrgId]
                    ? groupedProblems[selectedOrgId].orgName
                    : "No Organization Selected";
                return (
                  <h2 className="text-base p-2 bg-bcolor text-white rounded-full my-2">
                    Organization :  {selectedOrgId !== userId ? selectedOrgName : "Your Workspace"}
                  </h2>
                );
              })()}
            </div>
          )}
          <div className="pb-3">
            {open ? (
              <div className="flex flex-row justify-center w-full items-center px-2 space-x-2">
                <h3
                  className={`text-base font-semibold ${view === "Admin" ? "text-[#65558F]" : "text-[#79747E80]"
                    } m-0`}
                >
                  Admin View
                </h3>
                <CustomSwitch
                  checked={view === "Sprint"}
                  onChange={handleViewToggle}
                />
                <h3
                  className={`text-base font-semibold ${view === "Sprint" ? "text-[#65558F]" : "text-[#79747E80]"
                    } m-0`}
                >
                  Sprinter View
                </h3>
              </div>
            ) : (
              <div className="text-bcolor flex flex-row space-x-2">
                <h1>{view}</h1> <Radio />
              </div>
            )}
          </div>
        </div>


        <div className="overflow-y-auto h-[57%] mt-4">
          {orgLoading || problemLoading || sprintLoading || usersLoading ? (
            <div className="flex justify-center items-center h-full">
              <CircularProgress />
            </div>
          ) : view === "Admin" ? (
            <List component="nav" className="flex flex-col space-y-1">
              {Object.entries(groupedProblems).map(([orgId, { orgName, problems }]) => {
                const orgObj = userOrganizations.find((o) => o.id === orgId);
                const isWorkspace = orgObj && orgObj.id === userId;

                return (
                  <div key={orgId}>
                    {/* Organization Level */}
                    <ListItem
                      button
                      onClick={() => {
                        setOpenMenus((prev) => ({
                          ...prev,
                          [orgId]: !prev[orgId],
                        }));
                        localStorage.setItem("orgId", orgId);
                      }}
                    >
                      {open && (
                        <div className="flex justify-between space-x-2 w-full">
                          {isWorkspace ? (
                            <> <EditOutlined
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenOrgModal(orgObj);
                              }}
                              style={{ cursor: "pointer", marginTop: "5px" }}
                            />
                              <ListItemText primary={"Your Workspace"} /></>
                          ) : <ListItemText primary={orgName} />}


                        </div>
                      )}
                      {openMenus[orgId] ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <MuiCollapse in={openMenus[orgId]} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {problems.map((problem) => {
                          const uniqueSprinters = getUniqueSprinters(problem);
                          const completionPercentage = calculateCompletion(problem);

                          return (
                            <div key={problem.id}>
                              <ListItem
                                button
                                sx={{
                                  "&:hover": {
                                    backgroundColor: "#65558f",
                                  },
                                }}
                                className={`flex space-x-2 items-center cursor-pointer ${openMenus[`problem-${problem.id}`]
                                  ? "bg-bcolor rounded-lg text-white"
                                  : "text-[#4a4459]"
                                  } ${open ? "justify-start" : "justify-center"}`}
                                onClick={() => {
                                  setSelectedMenu(`problem-${problem.id}`);
                                  toggleMenu(`problem-${problem.id}`, problem.id);
                                }}
                              >
                                {open && (
                                  <div className="flex items-center justify-between  space-x-2 w-full">
                                    <CircularProgress
                                      variant="determinate"
                                      value={completionPercentage}
                                      size={30}
                                      thickness={5}
                                      style={{
                                        color:
                                          selectedMenu === `problem-${problem.id}`
                                            ? "#ffffff"
                                            : "#65558f",
                                      }}
                                    />
                                    <ListItemText
                                      primary={problem.name.substring(0, 20) + "..."}
                                    />
                                  </div>
                                )}
                                {openMenus[`problem-${problem.id}`] ? (
                                  <ExpandLess />
                                ) : (
                                  <ExpandMore />
                                )}
                              </ListItem>
                              <MuiCollapse
                                in={openMenus[`problem-${problem.id}`]}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List component="div" disablePadding>
                                  {uniqueSprinters.map((sprinter) => (
                                    <div key={sprinter.id} className="pl-4">
                                      <ListItem
                                        button
                                        onClick={() =>
                                          toggleMenu(`sprinter-${sprinter.id}`)
                                        }
                                      >
                                        {openMenus[`sprinter-${sprinter.id}`] ? (
                                          <ExpandLess />
                                        ) : (
                                          <ExpandMore />
                                        )}
                                        {open && (
                                          <ListItemText
                                            primary={sprinter.name + "'s sprints"}
                                          />
                                        )}
                                      </ListItem>
                                      <MuiCollapse
                                        in={openMenus[`sprinter-${sprinter.id}`]}
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <List component="div" disablePadding>
                                          {getSprintsBySprinter(
                                            problem.id,
                                            sprinter.name
                                          )?.map((sprint) => (
                                            <ListItem
                                              key={sprint.id}
                                              button
                                              onClick={() =>
                                                toggleMenu(
                                                  `sprint-${sprint.id}`,
                                                  problem.id,
                                                  sprint
                                                )
                                              }
                                              className="flex items-center justify-between px-3 py-2"
                                            >
                                              <div className="flex justify-between items-center space-x-2 w-full">
                                                <CircularProgress
                                                  variant="determinate"
                                                  value={calculateSprintProgress(
                                                    sprint
                                                  )}
                                                  size={30}
                                                  thickness={5}
                                                  style={{
                                                    color: getStatusColor(
                                                      sprint.status
                                                    ),
                                                  }}
                                                />
                                                <div
                                                  className={`ml-2 pl-5 w-[80%] h-[33px] pt-1 rounded-full`}
                                                  style={{
                                                    backgroundColor: getStatusColor(
                                                      sprint.status
                                                    ),
                                                    color: "#fff",
                                                  }}
                                                >
                                                  {sprint.name}
                                                </div>
                                              </div>
                                            </ListItem>
                                          ))}
                                        </List>
                                      </MuiCollapse>
                                    </div>
                                  ))}
                                </List>
                              </MuiCollapse>
                            </div>
                          );
                        })}
                      </List>
                    </MuiCollapse>
                  </div>
                );
              })}
            </List>
          ) : (
            <List component="nav" className="flex flex-col space-y-1">
            {Object.entries(userSprintsGrouped).map(([orgName, problems]) => (
              <div key={orgName} className="border-b-[1px] border-[#cac4d0] w-full">
                {/* Organization Level */}
                <ListItem
                  onClick={() =>
                    setOpenMenus((prev) => ({
                      ...prev,
                      [orgName]: !prev[orgName],
                    }))
                  }
                  className={`flex space-x-2 items-center cursor-pointer ${openMenus[orgName]
                    ? "bg-bcolor rounded-lg text-white"
                    : "text-[#4a4459]"
                    } ${open ? "justify-start" : "justify-center"}`}
                >
                  {open && (openMenus[orgName] ? <ExpandLess /> : <ExpandMore />)}
                  {open && <ListItemText primary={orgName} />}
                </ListItem>
          
                {/* Problems under the Organization */}
                <MuiCollapse in={openMenus[orgName]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {Object.entries(problems).map(([problemName, { problemId, sprints }]) => {
                      // Calculate average progress for the problem's sprints
                      const totalProgress = sprints.reduce(
                        (sum, sprint) => sum + calculateSprintProgress(sprint),
                        0
                      );
                      const averageProgress = sprints.length > 0 ? totalProgress / sprints.length : 0;
          
                      return (
                        <div key={problemId}>
                          {/* Problem Level */}
                          <ListItem
                            onClick={() =>
                              setOpenMenus((prev) => ({
                                ...prev,
                                [problemName]: !prev[problemName],
                              }))
                            }
                            className={`flex space-x-2 items-center cursor-pointer ${openMenus[problemName]
                              ? "bg-secondary rounded-lg text-white"
                              : "text-[#4a4459]"
                              } ${open ? "justify-start" : "justify-center"}`}
                          >
                            {open && (openMenus[problemName] ? <ExpandLess /> : <ExpandMore />)}
                            {open && <ListItemText primary={problemName.substring(0, 20) + "..."} />}
                            <div className={`${open ? "" : "flex justify-center w-full"}`}>
                              <CircularProgress
                                variant="determinate"
                                value={averageProgress}
                                size={30}
                                thickness={5}
                                style={{
                                  color: openMenus[problemName] ? "#ffffff" : "#65558f",
                                }}
                              />
                            </div>
                          </ListItem>
          
                          {/* Sprints under the Problem */}
                          <MuiCollapse in={openMenus[problemName]} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              {sprints.map((sprint) => (
                                <ListItem
                                  key={sprint.id}
                                  button
                                  onClick={() =>
                                    toggleMenu(`sprint-${sprint.id}`, problemId, sprint)
                                  }
                                  className="flex items-center justify-between px-3 py-2"
                                >
                                  <div className="flex justify-between items-center space-x-2 w-full">
                                    <CircularProgress
                                      variant="determinate"
                                      value={calculateSprintProgress(sprint)}
                                      size={30}
                                      thickness={5}
                                      style={{
                                        color: getStatusColor(sprint.status),
                                      }}
                                    />
                                    <div
                                      className={`ml-2 pl-5 w-[80%] h-[33px] pt-1 rounded-full`}
                                      style={{
                                        backgroundColor: getStatusColor(sprint.status),
                                        color: "#fff",
                                      }}
                                    >
                                      {sprint.name}
                                    </div>
                                  </div>
                                </ListItem>
                              ))}
                            </List>
                          </MuiCollapse>
                        </div>
                      );
                    })}
                  </List>
                </MuiCollapse>
              </div>
            ))}
          </List>
          )}
        </div>

        <div className="flex-grow" />
        <NavLink to="/tutorial">
          <div
            className={`w-full h-[50px] bg-[#00adb5] flex items-center px-4 space-x-3 ${open ? "justify-start" : "justify-center"
              }`}
          >
            <HelpIcon style={{ color: "white" }} />
            {open && <span className="text-white">Tutorials and Resources</span>}
          </div>
        </NavLink>
        <NavLink to="/aboutus">
          <div
            className={`w-full h-[50px] bg-[#00adb5] flex items-center px-4 space-x-3 ${open ? "justify-start" : "justify-center"
              }`}
          >
            <ErrorIcon style={{ color: "white" }} />
            {open && <span className="text-white">About Us</span>}
          </div>
        </NavLink>
      </Drawer>

      {/* Edit Organization Modal for the user's workspace or any org */}
      <Dialog
        open={openOrgModal}
        onClose={handleCloseOrgModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit {currentOrg?.name}</DialogTitle>
        <DialogContent>
          <div className="space-y-4 mt-4">
            {members.map((member, index) => (
              <div key={index} className="flex items-center space-x-4">
                <Autocomplete
                  options={usersData.allUsers}
                  style={{
                    width: 300,
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  }}
                  getOptionLabel={(option) => option.username || ""}
                  renderOption={(props, option) => (
                    <MenuItem {...props} key={option.id}>
                      {option.username} ({option.email})
                    </MenuItem>
                  )}
                  value={
                    usersData.allUsers.find((user) => user.id === member.userId) ||
                    null
                  }
                  onChange={(e, newValue) =>
                    setMembers((prev) =>
                      prev.map((m, i) =>
                        i === index ? { ...m, userId: newValue?.id || "" } : m
                      )
                    )
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Select User" fullWidth />
                  )}
                />
                <select
                  className="w-48 border border-gray-300 rounded-lg p-2 focus:outline-none"
                  value={member.accessLevel}
                  style={{
                    width: 300,
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  }}
                  onChange={(e) =>
                    setMembers((prev) =>
                      prev.map((m, i) =>
                        i === index ? { ...m, accessLevel: e.target.value } : m
                      )
                    )
                  }
                >
                  <option value="">Select Access Level</option>
                  <option value="Admin">Admin</option>
                  <option value="Creator">Creator</option>
                  <option value="Viewer">Viewer</option>
                </select>
                <IconButton color="error" onClick={() => handleRemoveMember(index)}>
                  <RemoveCircleOutline />
                </IconButton>
              </div>
            ))}
            <button
              type="button"
              className="px-4 py-2 bg-blue-500 text-white rounded-md"
              onClick={() => setMembers([...members, { userId: "", accessLevel: "" }])}
            >
              Add Member
            </button>
          </div>
          <div className="flex justify-end mt-4">
            <button
              className="px-4 py-2 bg-green-500 text-white rounded-md mr-2"
              onClick={handleSaveOrg}
            >
              Save
            </button>
            <button
              className="px-4 py-2 bg-gray-400 text-white rounded-md"
              onClick={handleCloseOrgModal}
            >
              Cancel
            </button>
          </div>
        </DialogContent>
      </Dialog>

      <ToastAlert />
    </div>
  );
};

export default Sidebar;